import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AuthState {
  _id: string;
  name: string;
  isAuth: boolean;
  isAdmin: boolean;
  isCompany: boolean;
  isBranch: boolean;
  isTeacher: boolean;
  isStudent: boolean;
  isParent: boolean;
}

const initialState: AuthState = {
  _id: "",
  name: "",
  isAdmin: false,
  isAuth: false,
  isBranch: false,
  isCompany: false,
  isParent: false,
  isStudent: false,
  isTeacher: false,
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state._id = action.payload._id;
      state.name = action.payload.name;
      state.isAdmin = action.payload.isAdmin;
      state.isAuth = action.payload.isAuth;
      state.isBranch = action.payload.isBranch;
      state.isCompany = action.payload.isCompany;
      state.isParent = action.payload.isParent;
      state.isStudent = action.payload.isStudent;
      state.isTeacher = action.payload.isTeacher;
    },
  },
});

export const { setAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.authReducer.isAuth;

export default authSlice.reducer;
