import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import { useAppSelector } from "../store/hooks";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetAllMyLecturesRoot,
  GetAllStudentRoot,
  GetByIdDesignationRoot,
  GetLectureByIdRoot,
  GetStudentAttendanceRoot,
} from "../utils/types";
import ReactPaginate from "react-paginate";

const Attendance = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const _id = useAppSelector(
    (state: { authReducer: { _id: any } }) => state.authReducer._id
  );
  const navigate = useNavigate();
  const { post, response, loading } =
    useFetch<GetAllMyLecturesRoot>("/lecture");

  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await post("/getMyLecture", {
        teacher: _id,
      })
        .then((res) => {
          if (res) {
            setRefreshing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setRefreshing(false);
        });
    })();
  }, []);
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return (
      <div>
        <AppProvider>
          <div>Something went wrong</div>
        </AppProvider>
      </div>
    );
  }
  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>My Lectures</h5>

                  {false && (
                    <Button onClick={() => navigate("/attendance?action=add")}>
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Subject</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Teacher</th>
                      <th>Branch</th>
                      <th>Batch</th>
                      <th>Standard</th>
                      <th>Attendance</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item: any, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>
                            {moment(item.date).format("DD-MM-YYYY") || ""}
                          </td>
                          <td>{item.subject.name || ""}</td>
                          <td>{item.fromTime || ""}</td>
                          <td>{item.toTime || ""}</td>
                          <td>{item.teacher.name || ""}</td>
                          <td>{item.branch.name || ""}</td>
                          <td>{item.batch.name || ""}</td>
                          <td>{item.standard.name || ""}</td>
                          <td>{item.isAttendance ? "Taken" : "Not taken"}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                size="sm"
                                variant="success"
                                onClick={() =>
                                  navigate(
                                    `/attendance?action=view&&id=${item._id}`
                                  )
                                }
                              >
                                Attendance
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/lecture");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getTeacher, response: teacherResponse } =
    useFetch<GetAllDesignationAPIRoot>("/teacher");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getBranch();

        await getStandard();

        await getSubject();

        await getTeacher();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: { isActive: boolean }) => {
    await post(values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    teacher: Yup.string().required(),
                    standard: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  initialValues={{
                    subject: "",
                    branch: "",
                    batch: "",
                    teacher: "",
                    standard: "",
                    fromTime: "",
                    toTime: "",
                    date: "",
                    isActive: true,
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>Select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik07"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetByIdDesignationRoot>("/lecture");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    name: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await get(`${parsed.id}`)
        .then((res) => {
          if (res.success) {
            console.log(res);

            setInitValues({
              isActive: res.data.isActive,
              name: res.data.name,
            });

            setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [parsed.id, get]);

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetLectureByIdRoot>("/lecture");
  const { get: getStudents, response: studentResponse } =
    useFetch<GetAllStudentRoot>("/student/getStudentsByLecture");
  const { post } = useFetch<{
    success: boolean;
    message: string;
    data: {};
  }>("/attendance");
  const [refreshing, setRefreshing] = useState(true);
  const { post: getAttendance, response: studentAttendanceResponse } =
    useFetch<GetStudentAttendanceRoot>("/attendance");
  const [attendanceData, setAttendanceData] = useState<{
    lecture: string;
    studentAttendance: { student: string; isPresent: boolean }[];
  }>({ lecture: "", studentAttendance: [] });
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      try {
        setRefreshing(true);
        if (parsed.id) {
          await get(`${parsed.id}`)
            .then((res) => {
              if (res) {
                if (res.success) {
                  setRefreshing(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setRefreshing(false);
            });

          setRefreshing(true);

          await getStudents(`${parsed.id}`)
            .then((res) => {
              if (res) {
                if (res.success) {
                  setRefreshing(false);

                  let temp: {
                    student: string;
                    isPresent: boolean;
                  }[] = [];

                  res.data.map((item) =>
                    temp.push({
                      isPresent: true,
                      student: item._id,
                    })
                  );

                  setAttendanceData({
                    lecture: parsed.id as string,
                    studentAttendance: temp,
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setRefreshing(false);
            });

          setRefreshing(true);

          await getAttendance("/getAttendance", {
            lecture: parsed.id as string,
          })
            .then((res) => {
              if (res) {
                if (res.success) {
                  setRefreshing(false);
                } else {
                  setRefreshing(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setRefreshing(false);
            });
        }
      } catch (err) {
        console.log(err);

        setRefreshing(false);
      }
    })();
  }, [parsed.id, get]);

  const handleAttendance = async () => {
    await post(attendanceData)
      .then(async (res) => {
        if (res.success) {
          toast.success(res.message);
          console.log(res);

          await getAttendance("/getAttendance", {
            lecture: parsed.id as string,
          })
            .then((res) => {
              console.log(res);
              if (res) {
                if (res.success) {
                  setRefreshing(false);
                } else {
                  setRefreshing(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setRefreshing(false);
            });

          setKey(Math.random());
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
        console.log(err);
      });
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <div onClick={() => navigate(-1)}>
                      <i
                        className="bi bi-arrow-left"
                        style={{
                          fontSize: "23px",
                        }}
                      ></i>
                    </div>
                    <div>
                      <h6>Attendance</h6>
                    </div>
                  </div>

                  {false && (
                    <Button size="sm" onClick={handleAttendance}>
                      Attendance
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body key={key}>
                <div>
                  {studentAttendanceResponse &&
                  studentAttendanceResponse.data &&
                  studentAttendanceResponse.data?.data ? (
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Total</div>
                              <div>
                                {
                                  studentAttendanceResponse.data?.data
                                    ?.studentAttendance?.length
                                }
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col>
                        <Card>
                          <Card.Body>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Present</div>
                              <div>
                                {
                                  studentAttendanceResponse.data?.data?.studentAttendance?.filter(
                                    (item) => item.isPresent === true
                                  ).length
                                }
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col>
                        <Card>
                          <Card.Body>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Absent</div>
                              <div>
                                {
                                  studentAttendanceResponse.data?.data?.studentAttendance?.filter(
                                    (item) => item.isPresent === false
                                  ).length
                                }
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body>
                            <div>Currently No Data Available</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>

        {false && (
          <div>
            <Container
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Card>
                <Card.Header>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <div>
                      <h6>Details</h6>
                    </div>
                  </div>
                </Card.Header>

                <Card.Body>
                  <div>
                    {response && response.ok && response.data && (
                      <Table bordered hover>
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td>{response.data?.data?.date || ""}</td>
                          </tr>

                          <tr>
                            <td>Subject</td>
                            <td>{response.data?.data?.subject?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>From</td>
                            <td>{response.data?.data?.fromTime || ""}</td>
                          </tr>

                          <tr>
                            <td>To</td>
                            <td>{response.data?.data?.toTime || ""}</td>
                          </tr>

                          <tr>
                            <td>Teacher</td>
                            <td>{response.data?.data?.teacher?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Branch</td>
                            <td>{response.data?.data?.branch?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Batch</td>
                            <td>{response.data?.data?.batch?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Standard</td>
                            <td>{response.data?.data?.standard?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Is Active</td>
                            <td>
                              {response.data?.data.isActive ? "Yes" : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Created At</td>
                            <td>
                              {moment(response.data?.data.createdAt).format(
                                "DD-MM-YYYY H:mm A"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        )}

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Mark Attendance</h6>

                  {true && (
                    <Button size="sm" onClick={handleAttendance}>
                      SUBMIT
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Mark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentResponse &&
                      studentResponse.ok &&
                      studentResponse.data &&
                      studentResponse?.data?.data.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?.name || ""}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <Form.Group as={Row} className="mb-3">
                                  <Col
                                    style={{
                                      display: "flex",
                                      gap: "1rem",
                                    }}
                                  >
                                    <Form.Check
                                      type="radio"
                                      label="Present"
                                      name={item.name}
                                      id={item._id}
                                      value={item._id}
                                      defaultChecked={
                                        studentAttendanceResponse
                                          ? studentAttendanceResponse.data
                                            ? studentAttendanceResponse.data
                                                ?.data
                                              ? studentAttendanceResponse.data
                                                  ?.data?.studentAttendance
                                                ? studentAttendanceResponse.data
                                                    ?.data?.studentAttendance
                                                    ?.length !== 0
                                                  ? studentAttendanceResponse.data?.data?.studentAttendance?.find(
                                                      (x) =>
                                                        x.student === item._id
                                                    )?.isPresent === true
                                                    ? true
                                                    : false
                                                  : true
                                                : true
                                              : true
                                            : true
                                          : true
                                      }
                                      onChange={(e) => {
                                        setAttendanceData({
                                          lecture: attendanceData.lecture,
                                          studentAttendance:
                                            attendanceData.studentAttendance.map(
                                              (x) =>
                                                x.student === item._id
                                                  ? {
                                                      student: x.student,
                                                      isPresent: true,
                                                    }
                                                  : x
                                            ),
                                        });
                                      }}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="Absent"
                                      name={item.name}
                                      id={item._id}
                                      value={item._id}
                                      defaultChecked={
                                        studentAttendanceResponse
                                          ? studentAttendanceResponse.data
                                            ? studentAttendanceResponse.data
                                                ?.data
                                              ? studentAttendanceResponse.data
                                                  ?.data?.studentAttendance
                                                ? studentAttendanceResponse.data
                                                    ?.data?.studentAttendance
                                                    ?.length !== 0
                                                  ? studentAttendanceResponse.data?.data?.studentAttendance?.find(
                                                      (x) =>
                                                        x.student === item._id
                                                    )?.isPresent === false
                                                    ? true
                                                    : false
                                                  : false
                                                : false
                                              : false
                                            : false
                                          : false
                                      }
                                      onChange={(e) => {
                                        setAttendanceData({
                                          lecture: attendanceData.lecture,
                                          studentAttendance:
                                            attendanceData.studentAttendance.map(
                                              (x) =>
                                                x.student === item._id
                                                  ? {
                                                      student: x.student,
                                                      isPresent: false,
                                                    }
                                                  : x
                                            ),
                                        });
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Attendance;
