import { Formik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetByIdDesignationRoot,
} from "../utils/types";

const Standard = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }
  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Standard</h5>

                  {true && (
                    <Button onClick={() => navigate("/standard?action=add")}>
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Is Active</th>
                      <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item: any, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>{item.name || ""}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/standard?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/standard");

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Standard</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetByIdDesignationRoot>("/standard");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    name: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await get(`${parsed.id}`)
        .then((res) => {
          if (res.success) {
            setInitValues({
              isActive: res.data.isActive,
              name: res.data.name,
            });

            setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [parsed.id, get]);

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Standard</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default Standard;
