import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import { CustomMultiSelect } from "../components/CustomMultiSelect";
import CustomPageLayout from "../components/CustomPageLayout";
import { API_URL } from "../utils/constants";
import {
  AddDesignationRoot,
  FileUploadRoot,
  GetAllBranchUserDaum,
  GetAllBranchUserRoot,
  GetAllDesignationAPIRoot,
  GetBranchUserByIdRoot,
} from "../utils/types";

const BranchUser = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllBranchUserRoot>("/branchUser");
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }
  const currentData: GetAllBranchUserDaum[] = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  ) as GetAllBranchUserDaum[];
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            fluid
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Branch Admin</h5>

                  {true && (
                    <Button onClick={() => navigate("/branchUser?action=add")}>
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Branch</th>
                      {/* <th>Email</th>
                      <th>Contact</th>
                      <th>Address</th> */}
                      <th>Gender</th>
                      {/* <th>Photo</th> */}
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>{item?.name || ""}</td>
                          <td>{item?.username || ""}</td>
                          <td>
                            {item?.branch?.map((x) => <div>{x.name}</div>) ||
                              ""}
                          </td>
                          {/* <td>{item?.email || ""}</td>
                          <td>{item?.contact || ""}</td>
                          <td>{item?.address || ""}</td> */}
                          <td>{item?.gender?.name || ""}</td>
                          {/* <td>
                            <a
                              href={`${FILES_URL}/${item?.photo}`}
                              target="_blank"
                            >
                              <Button size="sm" variant="success">
                                VIEW
                              </Button>
                            </a>
                          </td> */}
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/branchUser?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { get: getGender, response: genderResponse } =
    useFetch<GetAllDesignationAPIRoot>("/gender");
  const { post } = useFetch<AddDesignationRoot>("/branchUser");
  const { get, response, loading } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const [refreshing, setRefreshing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filePath, setFilePath] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      await getGender();

      await get();

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      alert(err);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      // Verify if the selected file is an image
      const file = selectedFiles[0];
      if (!file.type.startsWith("image/")) {
        throw new Error("Please select an image file");
      }

      // Optional: You can further restrict based on file extensions if needed
      const acceptedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!fileExtension || !acceptedExtensions.includes(fileExtension)) {
        throw new Error(
          "Unsupported file extension. Please select an image file."
        );
      }

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then(async (res) => {
          return await res.json();
        })
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    isActive: boolean;
    dateOfBirth: string;
    email: string;
    contact: string;
    gender: string;
    address: string;
    photo: string;
  }) => {
    await post({ ...values, photo: filePath })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading || refreshing) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Branch User</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required(),
                    address: Yup.string(),
                    email: Yup.string(),
                    contact: Yup.number(),
                    gender: Yup.string(),
                    dateOfBirth: Yup.string(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    username: "",
                    password: "",
                    branch: [],
                    address: "",
                    email: "",
                    contact: "",
                    gender: "",
                    dateOfBirth: "",
                    photo: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Password <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="3" className="mb-3">
                          <Form.Label>
                            Profile Photo
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="photo"
                            value={values.photo}
                            onChange={handleFileUpload}
                            isValid={touched.photo && !errors.photo}
                            isInvalid={!!errors.photo}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.photo}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            isValid={touched.dateOfBirth && !errors.dateOfBirth}
                            isInvalid={
                              !!touched.dateOfBirth && !!errors.dateOfBirth
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateOfBirth}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Gender <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="gender"
                            onChange={handleChange}
                            value={values.gender}
                            isInvalid={!!touched.gender && !!errors.gender}
                          >
                            <option value={""}>Select</option>

                            {genderResponse &&
                              genderResponse.data &&
                              genderResponse.data?.data &&
                              genderResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Address <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <CustomMultiSelect
                          data={
                            response.data?.data
                              ?.sort((a, b) => {
                                if (a.name.toLowerCase() < b.name.toLowerCase())
                                  return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="branch"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Branch"
                          value={values.branch}
                          handleChange={handleChange}
                        />

                        <Form.Group as={Col} md="3" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const navigate = useNavigate();
  const { get: getGender, response: genderResponse } =
    useFetch<GetAllDesignationAPIRoot>("/gender");
  const {
    get,
    post,
    response: branchUserReponse,
  } = useFetch<GetBranchUserByIdRoot>("/branchUser");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState<{
    name: string;
    username: string;
    branch?: string[];
    address: string;
    email: string;
    contact: string;
    gender: string;
    dateOfBirth: string;
    photo: string;
    isActive: boolean;
  }>({
    name: "",
    username: "",
    branch: [],
    address: "",
    email: "",
    contact: "",
    gender: "",
    dateOfBirth: "",
    photo: "",
    isActive: true,
  });
  const [key, setKey] = useState(Math.random());
  const { get: getBranch, response } =
    useFetch<GetAllDesignationAPIRoot>("/branch");

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      await getGender();

      await getBranch();

      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res.success) {
              if (res.data) {
                let temp = res.data?.branch.map((x) => x._id);
                setInitValues({
                  isActive: res.data?.isActive || false,
                  name: res.data?.name || "",
                  username: res.data?.username || "",
                  address: res.data?.address || "",
                  contact: res.data?.contact || "",
                  dateOfBirth: res.data?.dateOfBirth || "",
                  email: res.data?.email || "",
                  gender: res.data?.gender?._id || "",
                  photo: "",
                  branch: temp,
                });
              }

              setKey(Math.random());

              setRefreshing(false);
            } else {
              setRefreshing(false);
            }
          })
          .catch((err) => {
            setRefreshing(false);
            alert(err);
          });
      }
    };

    fetchData();
  }, []);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      // Verify if the selected file is an image
      const file = selectedFiles[0];
      if (!file.type.startsWith("image/")) {
        throw new Error("Please select an image file");
      }

      // Optional: You can further restrict based on file extensions if needed
      const acceptedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!fileExtension || !acceptedExtensions.includes(fileExtension)) {
        throw new Error(
          "Unsupported file extension. Please select an image file."
        );
      }

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then(async (res) => {
          return await res.json();
        })
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    isActive: boolean;
    dateOfBirth: string;
    email: string;
    contact: string;
    gender: string;
    address: string;
    photo: string;
  }) => {
    await post(`${parsed.id}`, {
      ...values,
      photo: filePath ? filePath : branchUserReponse.data?.data?.photo,
    })
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            fluid
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Branch user</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    address: Yup.string().required(),
                    email: Yup.string().required(),
                    contact: Yup.number().required(),
                    gender: Yup.string().required(),
                    dateOfBirth: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="3" className="mb-3">
                          <Form.Label>
                            Profile Photo
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="photo"
                            value={values.photo}
                            onChange={handleFileUpload}
                            isValid={touched.photo && !errors.photo}
                            isInvalid={!!errors.photo}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.photo}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            isValid={touched.dateOfBirth && !errors.dateOfBirth}
                            isInvalid={
                              !!touched.dateOfBirth && !!errors.dateOfBirth
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateOfBirth}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Gender <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="gender"
                            onChange={handleChange}
                            value={values.gender}
                            isInvalid={!!touched.gender && !!errors.gender}
                          >
                            <option value={""}>Select</option>

                            {genderResponse &&
                              genderResponse.data &&
                              genderResponse.data?.data &&
                              genderResponse.data?.data.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Address <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <CustomMultiSelect
                          data={
                            response.data?.data
                              ?.sort((a, b) => {
                                if (a.name.toLowerCase() < b.name.toLowerCase())
                                  return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="branch"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Branch"
                          value={values.branch || []}
                          handleChange={handleChange}
                        />

                        <Form.Group as={Col} md="3" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetBranchUserByIdRoot>("/branchUser");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View branchUser</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{response.data?.data.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Username</td>
                          <td>{response.data?.data.username || ""}</td>
                        </tr>

                        <tr>
                          <td>Branch</td>
                          <td>{response.data?.data?.branch[0].name || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default BranchUser;
