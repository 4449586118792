import { Formik } from "formik";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomPageLayout from "../../components/CustomPageLayout";
import {
  GetAllDesignationAPIRoot,
  GetManageBranchByID,
  ManageBranchDaum,
  ManageBranchRoot,
} from "../../utils/types";

const ManageBranch = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    post,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<ManageBranchRoot>("/manageBranch");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getDay, response: dayResponse } =
    useFetch<GetAllDesignationAPIRoot>("/day");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");

  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    (async () => await fetchData())();
    fetchData();
  }, []);

  const fetchData = async () => {
    setRefreshing(true);

    try {
      await getStandard();

      await getDay();

      await getBranch();

      await get();

      setRefreshing(false);
    } catch (err) {
      console.log(err);
    }

    setRefreshing(false);
  };

  const handleSubmit = async (values: {
    branch: string[];
    day: string[];
    firstHalfStandard: string[];
    secondHalfStandard: string[];
  }) => {
    try {
      setRefreshing(true);

      await post({ ...values });

      await fetchData();

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      alert(err);
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const currentData =
    response.ok &&
    response.data &&
    response.data?.data &&
    Array.isArray(response.data?.data)
      ? (response?.data?.data?.slice(
          currentPage * itemsPerPage,
          (currentPage + 1) * itemsPerPage
        ) as ManageBranchDaum[])
      : [];

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Manage Day & Standard</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    branch: [],
                    day: [],
                    firstHalfStandard: [],
                    secondHalfStandard: [],
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <CustomMultiSelect
                          data={
                            branchResponse.data?.data
                              ?.sort((a, b) => {
                                if (a.name.toLowerCase() < b.name.toLowerCase())
                                  return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="branch"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Branch"
                          value={values.branch}
                          handleChange={handleChange}
                        />

                        <CustomMultiSelect
                          data={
                            dayResponse.data?.data
                              ?.sort((a, b) => {
                                if (a.name.toLowerCase() < b.name.toLowerCase())
                                  return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="day"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Day"
                          value={values.day}
                          handleChange={handleChange}
                        />

                        <CustomMultiSelect
                          data={
                            standardResponse.data?.data
                              ?.sort(
                                (a, b) => parseInt(a.name) - parseInt(b.name)
                              )
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="firstHalfStandard"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="First Half"
                          value={values.firstHalfStandard}
                          handleChange={handleChange}
                        />

                        <CustomMultiSelect
                          data={
                            standardResponse.data?.data
                              ?.sort(
                                (a, b) => parseInt(a.name) - parseInt(b.name)
                              )
                              .map((x) => {
                                return { label: x.name, value: x._id };
                              }) || []
                          }
                          name="secondHalfStandard"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Second Half"
                          value={values.secondHalfStandard}
                          handleChange={handleChange}
                        />

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Manage Branch</h5>
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Branch</th>
                      <th>Day</th>
                      <th>First Half</th>
                      <th>Second Half</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>{item?.branch?.name || ""}</td>
                          <td>
                            {item.day.map((x) => (
                              <div>{x.name}</div>
                            ))}
                          </td>
                          <td>
                            {item.firstHalfStandard.map((x) => (
                              <div>{x.name}</div>
                            ))}
                          </td>
                          <td>
                            {item.secondHalfStandard.map((x) => (
                              <div>{x.name}</div>
                            ))}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const [filePath, setFilePath] = useState("");
  const navigate = useNavigate();
  const { post } = useFetch<ManageBranchRoot>("/manageBranch");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getDay, response: dayResponse } =
    useFetch<GetAllDesignationAPIRoot>("/day");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getStandard();

        await getSubject();

        await getDay();

        await getBranch();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    branch: string[];
    day: string[];
    standard: string[];
  }) => {
    await post({ ...values })
      .then(async (res) => {
        if (res.success) {
          // navigate(-1);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        console.log(err);
      });
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Manage Day & Standard</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    branch: [],
                    day: [],
                    standard: [],
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <CustomMultiSelect
                          data={
                            branchResponse.data?.data?.map((x) => {
                              return { label: x.name, value: x._id };
                            }) || []
                          }
                          name="day"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Day"
                          value={values.day}
                          handleChange={handleChange}
                        />

                        <CustomMultiSelect
                          data={
                            dayResponse.data?.data?.map((x) => {
                              return { label: x.name, value: x._id };
                            }) || []
                          }
                          name="day"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Day"
                          value={values.day}
                          handleChange={handleChange}
                        />

                        <CustomMultiSelect
                          data={
                            standardResponse.data?.data?.map((x) => {
                              return { label: x.name, value: x._id };
                            }) || []
                          }
                          name="standard"
                          setFieldValue={setFieldValue}
                          isRequired
                          label="Standard"
                          value={values.standard}
                          handleChange={handleChange}
                        />

                        {/* <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Day <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="day"
                            onChange={handleChange}
                            value={values.day}
                            isInvalid={!!touched.day && !!errors.day}
                          >
                            <option value={""}>Select</option>

                            {dayResponse &&
                              dayResponse.data &&
                              dayResponse.data?.data &&
                              dayResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.day}
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        {/* <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        {/* <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group> */}

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const CustomMultiSelect: FC<{
  data: { label: string; value: string }[];
  setFieldValue: any;
  name: string;
  label: string;
  isRequired: boolean;
  value: string[];
  handleChange: any;
}> = ({
  data,
  name,
  setFieldValue,
  label,
  isRequired,
  value,
  handleChange,
}) => {
  return (
    <Form.Group as={Col} md="3" className="mb-3">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>

      {data.map((x) => (
        <Form.Check
          name={name}
          label={x.label}
          value={x.value}
          onChange={handleChange}
          defaultChecked={false}
        />
      ))}
    </Form.Group>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    subject: "",
    standard: "",
    day: "",
    branch: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const { post, get, response } =
    useFetch<GetManageBranchByID>("/manageBranch");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getDay, response: dayResponse } =
    useFetch<GetAllDesignationAPIRoot>("/day");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getStandard();

        await getSubject();

        await getDay();

        await getBranch();

        await get(`${parsed.id}`)
          .then((res) => {
            if (res.success) {
              setInitValues({
                branch: res.data.branch || "",
                day: res.data.day.name || "",
                standard: res.data.standard.name || "",
                subject: res.data.subject.name || "",
                isActive: res.data.isActive,
              });

              setKey(Math.random());
            }
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    day: string;
    subject: string;
    standard: string;
    branch: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, {
      ...values,
    })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        console.log(err);
      });
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Manage Branch</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    branch: Yup.string().required(),
                    day: Yup.string().required(),
                    subject: Yup.string().required(),
                    standard: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  key={key}
                  enableReinitialize
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Day <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="day"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.day && !!errors.day}
                          >
                            <option value={""}>Select</option>

                            {dayResponse &&
                              dayResponse.data &&
                              dayResponse.data?.data &&
                              dayResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.day}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default ManageBranch;
