import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import * as Yup from "yup";
import Logo from "../assets/scpl-back-22.png";
import { setAuth } from "../store/authReducer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { LoginRoot } from "../utils/types";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { post } = useFetch<LoginRoot>("/auth/login");
  const isAuth = useAppSelector((state) => state.authReducer.isAuth);

  useEffect(() => {
    if (isAuth) {
      navigate("/dashboard");
    }
  }, [isAuth]);

  useEffect(() => {
    const userAuth = localStorage.getItem("userAuth");

    if (userAuth) {
      const {
        _id,
        name,
        isAdmin,
        isAuth,
        isBranch,
        isCompany,
        isParent,
        isStudent,
        isTeacher,
      } = JSON.parse(userAuth as string);

      if (isAuth) {
        dispatch(
          setAuth({
            _id: _id,
            name: name,
            isAdmin: isAdmin,
            isAuth: isAuth,
            isBranch: isBranch,
            isCompany: isCompany,
            isParent: isParent,
            isStudent: isStudent,
            isTeacher: isTeacher,
          })
        );

        navigate("/dashboard");
      }
    }
  }, []);

  const handleSubmit = async (values: { email: string; password: string }) => {
    await post({
      username: values.email,
      password: values.password,
    })
      .then((res) => {
        if (res.success) {
          if (
            res.data.isBranch ||
            res.data.isParent ||
            res.data.isStudent ||
            res.data.isTeacher
          ) {
            toast.error("You are not allowed");

            return;
          }

          dispatch(
            setAuth({
              _id: res.data._id,
              name: res.data.name,
              isAdmin: res.data.isAdmin,
              isAuth: true,
              isBranch: res.data.isBranch,
              isCompany: res.data.isCompany,
              isParent: res.data.isParent,
              isStudent: res.data.isStudent,
              isTeacher: res.data.isTeacher,
            })
          );

          localStorage.setItem(
            "userAuth",
            JSON.stringify({
              _id: res.data._id,
              name: res.data.name,
              isAdmin: res.data.isAdmin,
              isAuth: true,
              isBranch: res.data.isBranch,
              isCompany: res.data.isCompany,
              isParent: res.data.isParent,
              isStudent: res.data.isStudent,
              isTeacher: res.data.isTeacher,
            })
          );
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(135deg, #f8ad00 30%, #82ba26 100%)",
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 50,
          marginBottom: 140,
        }}
      >
        <Card
          style={{
            width: "25rem",
            marginTop: "5rem",
          }}
        >
          <Card.Body
            style={{
              padding: "2rem",
            }}
          >
            <Card.Title
              className="mb-3"
              style={{
                textAlign: "center",
              }}
            >
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <img src={Logo} width={300} height={"auto"} />
              </div>
            </Card.Title>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required(),
                password: Yup.string().required(),
              })}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <Form onChange={handleChange} onSubmit={handleSubmit}>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik01"
                        className="mb-3"
                      >
                        <Form.Label>
                          EMAIL <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <i className="bi bi-envelope"></i>
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            // isValid={touched.email && !errors.email}
                            isInvalid={!!touched.email && !!errors.email}
                          />
                        </InputGroup>

                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik02"
                        className="mb-3"
                      >
                        <Form.Label>
                          PASSWORD <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <i className="bi bi-key"></i>
                          </InputGroup.Text>
                          <Form.Control
                            type={isVisible ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            // isValid={touched.password && !errors.password}
                            isInvalid={!!touched.password && !!errors.password}
                          />
                          <InputGroup.Text
                            onClick={() => setIsVisible(!isVisible)}
                          >
                            {isVisible ? (
                              <i className="bi bi-eye"></i>
                            ) : (
                              <i className="bi bi-eye-slash"></i>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button type="submit">SUBMIT</Button>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Login;
