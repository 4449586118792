import { Formik } from "formik";
import _ from "lodash";
import { asyncMap } from "modern-async";
import moment from "moment";
import queryString from "query-string";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import { read, utils, writeFile } from "xlsx";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import { useAppSelector } from "../store/hooks";
import { API_URL, FILES_URL } from "../utils/constants";
import {
  AddDesignationRoot,
  FileUploadRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentDaum,
  GetAllStudentRoot,
  GetByIdStudentRoot,
  GetStudentFamilyByIdRoot,
  StudentAcademicByIDRoot,
  StudentParentsRoot,
  StundentAcademicByStudentIdRoot,
} from "../utils/types";

const Student = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

interface excelInputType {
  NAME: string | null;
  USERNAME: string | null;
  CONTACT: string | null;
  FATHER: string | null;
  MOTHER: string | null;
}

const NAME = 0;
const USERNAME = 1;
const CONTACT = 2;
const FATHER = 3;
const MOTHER = 4;

const Index = () => {
  const branchId = useAppSelector((state) => state.branchReducer._id);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [excelData, setExeclData] = useState<excelInputType[]>();
  const { post } = useFetch<AddDesignationRoot>("/student");
  const { get: getSchool, response: schoolResponse } =
    useFetch<GetAllDesignationAPIRoot>("/school");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const navigate = useNavigate();
  const {
    get,
    post: studentDataByBranch,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllStudentRoot>("/student");
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [branchId]);

  const getData = async () => {
    try {
      setRefreshing(true);

      await studentDataByBranch("/byBranch", {
        branchId: branchId,
      });

      await getStandard();

      await getBatch();

      await getBranch();

      setRefreshing(false);

      setKey(Math.random());
    } catch (err) {
      setRefreshing(false);

      alert(err);
    }
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        //@ts-ignore
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var result = utils.sheet_to_json(firstSheet, { header: 1 });

        const arr: excelInputType[] = [];

        result.map((x) => {
          Array.isArray(x) &&
            arr.push({
              NAME: x[NAME],
              USERNAME: x[USERNAME],
              CONTACT: x[CONTACT],
              FATHER: x[FATHER],
              MOTHER: x[MOTHER],
            });
        });

        const filterdData = arr.filter(
          (x) =>
            !(
              _.isEmpty(x.NAME) &&
              _.isEmpty(x.USERNAME) &&
              _.isEmpty(x.CONTACT) &&
              _.isEmpty(x.FATHER) &&
              _.isEmpty(x.MOTHER)
            )
        );

        setExeclData(filterdData.slice(1));
      };
      // @ts-ignore
      reader.readAsArrayBuffer(e.target.files[0]);

      toast.success("file readed successfully");
    } catch (err) {
      toast.error("Something went wrong while file upload");
    }
  };

  const handleImport = async () => {
    try {
      handleClose();

      setRefreshing(true);

      if (excelData && excelData.length !== 0) {
        await asyncMap(excelData, async (x) => {
          await post("/test", x)
            .then((res) => {})
            .catch((err) => console.log(err));
        });

        setRefreshing(false);

        toast.success("Students added");

        await getData();
      }
    } catch (err) {
      setRefreshing(false);
      toast.error("something went wrong");
    }
  };

  const handleExport = async () => {
    const arrData: string[][] = [];
    const headingArray: string[] = [
      "NAME",
      "USERNAME",
      "CONTACT",
      "FATHER",
      "MOTHER",
    ];

    arrData.push(headingArray);

    response.data?.data.map((x) => {
      arrData.push([
        x.name || "-",
        x.username || "-",
        x.contact || "-",
        x.fatherContact || "-",
        x.motherContact || "-",
      ]);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(
      workbook,
      "STUDENT-DATA-" + moment().format("DD-MM-YYYY-HH-MM-A") + ".xlsx"
    );
  };

  const handleSubmit = async (values: { branch: string; standard: string }) => {
    try {
      if (excelData && excelData.length !== 0) {
        handleClose();

        setRefreshing(true);

        await asyncMap(excelData, async (x) => {
          await post("/test", {
            ...x,
            branch: values.branch,
            standard: values.standard,
          });
        });

        setRefreshing(false);

        toast.success("Students added");

        await getData();
      } else {
        toast.error("Select Excel");
      }

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      toast.error("something went wrong");
    }
  };

  const currentData = response?.data?.data
    ?.sort((a, b) => {
      if (a.username < b.username) {
        return -1;
      } else {
        if (a.name > b.name) {
          return 1;
        } else return 0;
      }
    })
    .slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    ) as GetAllStudentDaum[];

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AppProvider>
          <Container
            fluid
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Body>
                <Spinner />
              </Card.Body>
            </Card>
          </Container>
        </AppProvider>
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Import Excel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  standard: "",
                  branch: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  standard: Yup.string().required(),
                  branch: Yup.string().required(),
                })}
                key={key}
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => {
                  return (
                    <div>
                      <Row>
                        <Col>
                          <Form.Group controlId="validationFormik02">
                            <Form.Label>Branch</Form.Label>
                            <Form.Select
                              size="sm"
                              aria-label="Default select example"
                              name="branch"
                              onChange={handleChange}
                              value={values.branch}
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value={""}>Select</option>

                              {branchResponse &&
                                branchResponse.data &&
                                branchResponse.data?.data &&
                                branchResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group controlId="validationFormik02">
                            <Form.Label>Standard</Form.Label>
                            <Form.Select
                              size="sm"
                              aria-label="Default select example"
                              name="standard"
                              onChange={handleChange}
                              value={values.standard}
                              isInvalid={
                                !!touched.standard && !!errors.standard
                              }
                            >
                              <option value={""}>Select</option>

                              {standardResponse &&
                                standardResponse.data &&
                                standardResponse.data?.data &&
                                standardResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Select excel file</Form.Label>
                            <Form.Control
                              type="file"
                              size="sm"
                              accept=".xlsx, .xls, .csv"
                              onChange={handleFileSelect}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem",
                          }}
                        >
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => handleSubmit()}
                          >
                            Save Changes
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              </Formik>
            </Modal.Body>
          </Modal>

          <Container
            fluid
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h5>Student</h5>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    {true && (
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleShow}
                      >
                        IMPORT
                      </Button>
                    )}

                    {true && (
                      <Button
                        variant="success"
                        size="sm"
                        onClick={handleExport}
                      >
                        EXPORT
                      </Button>
                    )}

                    {true && (
                      <Button
                        size="sm"
                        onClick={() => navigate("/student?action=add")}
                      >
                        ADD
                      </Button>
                    )}
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Contact</th>
                      <th>Father Contact</th>
                      <th>Mother Contact</th>
                      <th>Is Active</th>
                      <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>{item?.name || ""}</td>
                          <td>{item?.username || ""}</td>
                          <td>{item?.contact || ""}</td>
                          <td>{item?.fatherContact || ""}</td>
                          <td>{item?.motherContact || ""}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>

                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              {true && (
                                <Button
                                  size={"sm"}
                                  onClick={() =>
                                    navigate(
                                      `/student?action=view&&id=${item._id}`
                                    )
                                  }
                                  variant="success"
                                >
                                  View
                                </Button>
                              )}
                              {true && (
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(
                                      `/student?action=update&&type=personal&&id=${item._id}`
                                    )
                                  }
                                >
                                  UPDATE
                                </Button>
                              )}
                              <Button
                                size={"sm"}
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/student");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);

  const handleSubmit = async (values: {
    name: string;
    username: string;
    password: string;
    contact: string;
    fatherContact: string;
    motherContact: string;
    isActive: boolean;
  }) => {
    try {
      setRefreshing(true);

      await post(values).then((res) => {
        if (res.success) {
          navigate(-1);
        } else {
          setRefreshing(false);
          alert(res.message);
        }
      });

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);

      alert(err);
    }
  };

  if (parsed.type === "academic") {
    return (
      <div>
        <AddAcademic />
      </div>
    );
  } else if (parsed.type === "family") {
    return (
      <div>
        <AddFamily />
      </div>
    );
  }

  if (refreshing) {
    <div>
      <AppProvider>
        <Spinner color="black" />
      </AppProvider>
    </div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Student</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required(),
                    name: Yup.string().required(),
                    contact: Yup.string(),
                    fatherContact: Yup.string(),
                    motherContact: Yup.string(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    username: "",
                    contact: "",
                    fatherContact: "",
                    motherContact: "",
                    password: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Password <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Father Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fatherContact"
                            value={values.fatherContact}
                            onChange={handleChange}
                            isValid={
                              touched.fatherContact && !errors.fatherContact
                            }
                            isInvalid={!!errors.fatherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fatherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Mother Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="motherContact"
                            value={values.motherContact}
                            onChange={handleChange}
                            isValid={
                              touched.motherContact && !errors.motherContact
                            }
                            isInvalid={!!errors.motherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.motherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const AddAcademic = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/studentAcademic");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getSchool, response: schoolResponse } =
    useFetch<GetAllDesignationAPIRoot>("/school");
  const { get: getYear, response: yearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/year");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getSchool();

        await getStandard();

        await getYear();

        setRefreshing(false);
      } catch (err) {
        console.log(err);

        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    grade: string;
    percentage: string;
    year: string;
    standard: string;
    school: string;
    isActive: boolean;
  }) => {
    await post({ ...values, student: parsed.id })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Student Academic Details</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    grade: Yup.string().required(),
                    percentage: Yup.string().required(),
                    year: Yup.string().required(),
                    standard: Yup.string().required(),
                    school: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    grade: "",
                    percentage: "",
                    year: "",
                    standard: "",
                    school: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            School <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="school"
                            onChange={handleChange}
                            value={values.school}
                            isInvalid={!!touched.school && !!errors.school}
                          >
                            <option value={""}>Select</option>

                            {schoolResponse &&
                              schoolResponse.data &&
                              schoolResponse.data?.data &&
                              schoolResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.school}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Year <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="year"
                            onChange={handleChange}
                            value={values.year}
                            isInvalid={!!touched.year && !!errors.year}
                          >
                            <option value={""}>Select</option>

                            {yearResponse &&
                              yearResponse.data &&
                              yearResponse.data?.data &&
                              yearResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.year}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Percentage <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="percentage"
                            value={values.percentage}
                            onChange={handleChange}
                            isValid={touched.percentage && !errors.percentage}
                            isInvalid={!!errors.percentage}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.percentage}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Grade <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="grade"
                            value={values.grade}
                            onChange={handleChange}
                            isValid={touched.grade && !errors.grade}
                            isInvalid={!!errors.grade}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.grade}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const AddFamily = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { post } = useFetch<AddDesignationRoot>("/studentParent");
  const { get, response, loading } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);
      try {
        await get()
          .then((res) => {
            if (res.success) {
              setRefreshing(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(err);
          });
      } catch (err) {
        console.log(err);
        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    relation: string;
    name: string;
    occupation: string;
    contact1: string;
    contact2: string;
    email: string;
    isActive: boolean;
  }) => {
    await post({ ...values, student: parsed.id })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading || refreshing) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Family Details</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    relation: Yup.string().required(),
                    name: Yup.string().required(),
                    occupation: Yup.string().required(),
                    contact1: Yup.string().max(10).min(10).required(),
                    contact2: Yup.string().max(10).min(10),
                    email: Yup.string().email().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    contact1: "",
                    contact2: "",
                    email: "",
                    isActive: true,
                    name: "",
                    occupation: "",
                    relation: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Relation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="relation"
                            onChange={handleChange}
                            value={values.relation}
                            isInvalid={!!touched.relation && !!errors.relation}
                          >
                            <option value={""}>Select</option>

                            {response &&
                              response.data &&
                              response.data?.data &&
                              response.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.relation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Occupation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="occupation"
                            value={values.occupation}
                            onChange={handleChange}
                            isValid={touched.occupation && !errors.occupation}
                            isInvalid={!!errors.occupation}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.occupation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact 1 <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="contact1"
                            value={values.contact1}
                            onChange={handleChange}
                            isValid={touched.contact1 && !errors.contact1}
                            isInvalid={!!errors.contact1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact1}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact 2 <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type={"number"}
                            name="contact2"
                            value={values.contact2}
                            onChange={handleChange}
                            isValid={touched.contact2 && !errors.contact2}
                            isInvalid={!!errors.contact2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact2}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  if (parsed.type === "personal") {
    return (
      <div>
        <PersonalUpdate />
      </div>
    );
  } else if (parsed.type === "academic") {
    return (
      <div>
        <AcademicUpdate />
      </div>
    );
  } else if (parsed.type === "family") {
    return (
      <div>
        <FamilyUpdate />
      </div>
    );
  }

  return <div>UPDATE</div>;
};

const PersonalUpdate = () => {
  const navigate = useNavigate();
  const { get, post, response } = useFetch<GetByIdStudentRoot>("/student");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    name: "",
    username: "",
    email: "",
    address: "",
    dateOfBirth: "",
    contact: "",
    fatherContact: "",
    motherContact: "",
    school: "",
    standard: "",
    batch: "",
    board: "",
    gender: "",
    branch: "",
    photo: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const { get: getSchool, response: schoolResponse } =
    useFetch<GetAllDesignationAPIRoot>("/school");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getBoard, response: boardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/educationBoard");
  const { get: getGender, response: genderResponse } =
    useFetch<GetAllDesignationAPIRoot>("/gender");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const [isUploading, setIsUploading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      // Verify if the selected file is an image
      const file = selectedFiles[0];
      if (!file.type.startsWith("image/")) {
        throw new Error("Please select an image file");
      }

      // Optional: You can further restrict based on file extensions if needed
      const acceptedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!fileExtension || !acceptedExtensions.includes(fileExtension)) {
        throw new Error(
          "Unsupported file extension. Please select an image file."
        );
      }

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then(async (res) => {
          return await res.json();
        })
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getSchool();

        await getStandard();

        await getBoard();

        await getGender();

        await getBranch();

        if (parsed.id) {
          await get(`${parsed.id}`)
            .then((res) => {
              if (res.success) {
                if (res.data) {
                  setInitValues({
                    address: res.data?.address || "",
                    batch: res.data?.batch?._id || "",
                    board: res.data?.board?._id || "",
                    dateOfBirth: res.data?.dateOfBirth || "",
                    contact: res.data?.contact || "",
                    motherContact: res.data?.motherContact || "",
                    fatherContact: res.data?.fatherContact || "",
                    gender: res.data?.gender?._id || "",
                    isActive: res.data?.isActive || false,
                    name: res.data?.name || "",
                    school: res.data?.school?._id || "",
                    standard: res.data?.standard?._id || "",
                    username: res.data?.username || "",
                    email: res.data?.email || "",
                    branch: res.data?.branch?._id || "",
                    photo: "",
                  });

                  setKey(Math.random());
                }
              }
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    name: string;
    username: string;
    isActive: boolean;
    address: string;
    dateOfBirth: string;
    contact: string;
    motherContact: string;
    fatherContact: string;
    school: string;
    standard: string;
    batch: string;
    board: string;
    gender: string;
    branch: string;
    photo: string;
    email: string;
  }) => {
    await post(`${parsed.id}`, {
      ...values,
      photo: filePath ? filePath : response.data?.data?.photo,
    })
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Student</h6>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    contact: Yup.string().min(10).max(10).required(),
                    fatherContact: Yup.string().min(10).max(10),
                    motherContact: Yup.string().min(10).max(10),
                    dateOfBirth: Yup.string().required(),
                    branch: Yup.string().required(),
                    standard: Yup.string().required(),
                    batch: Yup.string().required(),
                    school: Yup.string().required(),
                    board: Yup.string().required(),
                    gender: Yup.string().required(),
                    address: Yup.string().required(),
                    email: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Email Address
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date of Birth
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            isValid={touched.dateOfBirth && !errors.dateOfBirth}
                            isInvalid={
                              !!touched.dateOfBirth && !!errors.dateOfBirth
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateOfBirth}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Father Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fatherContact"
                            value={values.fatherContact}
                            onChange={handleChange}
                            isValid={
                              touched.fatherContact && !errors.fatherContact
                            }
                            isInvalid={!!errors.fatherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fatherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Mother Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="motherContact"
                            value={values.motherContact}
                            onChange={handleChange}
                            isValid={
                              touched.motherContact && !errors.motherContact
                            }
                            isInvalid={!!errors.motherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.motherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            School <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="school"
                            onChange={handleChange}
                            value={values.school}
                            isInvalid={!!touched.school && !!errors.school}
                          >
                            <option value={""}>Select</option>

                            {schoolResponse &&
                              schoolResponse.data &&
                              schoolResponse.data?.data &&
                              schoolResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.school}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Board <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="board"
                            onChange={handleChange}
                            value={values.board}
                            isInvalid={!!touched.board && !!errors.board}
                          >
                            <option value={""}>Select</option>

                            {boardResponse &&
                              boardResponse.data &&
                              boardResponse.data?.data &&
                              boardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.board}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Gender <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="gender"
                            onChange={handleChange}
                            value={values.gender}
                            isInvalid={!!touched.gender && !!errors.gender}
                          >
                            <option value={""}>Select</option>

                            {genderResponse &&
                              genderResponse.data &&
                              genderResponse.data?.data &&
                              genderResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Address <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Profile Photo
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="photo"
                            value={values.photo}
                            onChange={handleFileUpload}
                            isValid={touched.photo && !errors.photo}
                            isInvalid={!!errors.photo}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.photo}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const AcademicUpdate = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<StudentAcademicByIDRoot>("/studentAcademic");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getSchool, response: schoolResponse } =
    useFetch<GetAllDesignationAPIRoot>("/school");
  const { get: getYear, response: yearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/year");
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    grade: "",
    percentage: "",
    year: "",
    standard: "",
    school: "",
    isActive: true,
  });
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getSchool();

        await getStandard();

        await getYear();

        if (parsed.id) {
          await get(`${parsed.id}`).then((res) => {
            if (res.success) {
              setInitValues({
                grade: res.data?.grade || "",
                isActive: res.data?.isActive || true,
                percentage: res.data?.percentage || "",
                school: res.data?.school || "",
                standard: res.data?.standard || "",
                year: res.data?.year || "",
              });

              setKey(Math.random());
            }
          });
        }

        setRefreshing(false);
      } catch (err) {
        console.log(err);

        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    grade: string;
    percentage: string;
    year: string;
    standard: string;
    school: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Student Academic Details</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    grade: Yup.string().required(),
                    percentage: Yup.string().required(),
                    year: Yup.string().required(),
                    standard: Yup.string().required(),
                    school: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            School <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="school"
                            onChange={handleChange}
                            value={values.school}
                            isInvalid={!!touched.school && !!errors.school}
                          >
                            <option value={""}>Select</option>

                            {schoolResponse &&
                              schoolResponse.data &&
                              schoolResponse.data?.data &&
                              schoolResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.school}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Year <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="year"
                            onChange={handleChange}
                            value={values.year}
                            isInvalid={!!touched.year && !!errors.year}
                          >
                            <option value={""}>Select</option>

                            {yearResponse &&
                              yearResponse.data &&
                              yearResponse.data?.data &&
                              yearResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.year}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Percentage <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="percentage"
                            value={values.percentage}
                            onChange={handleChange}
                            isValid={touched.percentage && !errors.percentage}
                            isInvalid={!!errors.percentage}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.percentage}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Grade <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="grade"
                            value={values.grade}
                            onChange={handleChange}
                            isValid={touched.grade && !errors.grade}
                            isInvalid={!!errors.grade}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.grade}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const FamilyUpdate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get: getData, post } =
    useFetch<GetStudentFamilyByIdRoot>("/studentParent");
  const { get, response, loading } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    contact1: "",
    contact2: "",
    email: "",
    isActive: true,
    name: "",
    occupation: "",
    relation: "",
  });
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await get();

        await getData(`${parsed.id}`).then((res) => {
          if (res.success) {
            console.log(res);

            setInitValues({
              contact1: res.data.contact1,
              contact2: res.data.contact2,
              email: res.data.email,
              isActive: res.data.isActive,
              name: res.data.name,
              occupation: res.data.occupation,
              relation: res.data.relation,
            });

            setKey(Math.random());

            setRefreshing(false);
          }
        });
      } catch (err) {
        console.log(err);

        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    relation: string;
    name: string;
    occupation: string;
    contact1: string;
    contact2: string;
    email: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div>
        <AppProvider>
          <div>
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Family Details</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    relation: Yup.string().required(),
                    name: Yup.string().required(),
                    occupation: Yup.string().required(),
                    contact1: Yup.string().max(10).min(10).required(),
                    contact2: Yup.string().max(10).min(10),
                    email: Yup.string().email().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Relation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="relation"
                            onChange={handleChange}
                            value={values.relation}
                            isInvalid={!!touched.relation && !!errors.relation}
                          >
                            <option value={""}>Select</option>

                            {response &&
                              response.data &&
                              response.data?.data &&
                              response.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.relation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Occupation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="occupation"
                            value={values.occupation}
                            onChange={handleChange}
                            isValid={touched.occupation && !errors.occupation}
                            isInvalid={!!errors.occupation}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.occupation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact 1 <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="contact1"
                            value={values.contact1}
                            onChange={handleChange}
                            isValid={touched.contact1 && !errors.contact1}
                            isInvalid={!!errors.contact1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact1}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact 2 <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type={"number"}
                            name="contact2"
                            value={values.contact2}
                            onChange={handleChange}
                            isValid={touched.contact2 && !errors.contact2}
                            isInvalid={!!errors.contact2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact2}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetByIdStudentRoot>("/student");
  const {
    get: getStudentAcademics,
    delete: deleteStudentAcademicDetails,
    response: studentAcademicsResponse,
  } = useFetch<StundentAcademicByStudentIdRoot>("/studentAcademic");
  const {
    post: getStudenParents,
    del,
    response: studentParentsResponse,
  } = useFetch<StudentParentsRoot>("/studentParent");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    fetchData();
  }, [parsed.id, get]);

  const fetchData = async () => {
    setRefreshing(true);
    if (parsed.id) {
      await get(`${parsed.id}`)
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setRefreshing(false);
        });

      setRefreshing(true);

      await getStudentAcademics(`/studentAademicByStudentId/${parsed.id}`)
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setRefreshing(false);
        });

      setRefreshing(true);

      await getStudenParents("/getParentsByStudent", {
        student: parsed.id,
      })
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setRefreshing(false);
        });
    }
  };

  const handleStudentAcademicDelete = async (_id: string) => {
    try {
      await deleteStudentAcademicDetails(`${_id}`)
        .then(async (res) => {
          if (res.success) {
            await fetchData();
          }

          console.log(res);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const handleStudentFamilyDelete = async (_id: string) => {
    try {
      await del(`${_id}`)
        .then(async (res) => {
          if (res.success) {
            await fetchData();
          }

          console.log(res);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return (
      <div>
        <AppProvider>
          <div>Something went wrong</div>
        </AppProvider>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <AppProvider>
          <div>{error.message}</div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Student Details</h6>
                  </div>
                </div>
              </Card.Header>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Personal</h6>

                  {false && (
                    <Button
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/student?action=update&&type=personal&&id=${parsed.id}`
                        )
                      }
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response &&
                    response.ok &&
                    response.data &&
                    response.data?.data && (
                      <Table bordered hover>
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>{response.data?.data?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Username</td>
                            <td>{response.data?.data?.username || ""}</td>
                          </tr>

                          <tr>
                            <td>Date of Birth</td>
                            <td>{response.data?.data?.dateOfBirth || ""}</td>
                          </tr>

                          <tr>
                            <td>Contact</td>
                            <td>{response.data?.data?.contact || ""}</td>
                          </tr>

                          <tr>
                            <td>Father Contact</td>
                            <td>{response.data?.data?.fatherContact || ""}</td>
                          </tr>

                          <tr>
                            <td>Mother Contact</td>
                            <td>{response.data?.data?.motherContact || ""}</td>
                          </tr>

                          <tr>
                            <td>Email</td>
                            <td>{response.data?.data?.email || ""}</td>
                          </tr>

                          <tr>
                            <td>Branch</td>
                            <td>{response.data?.data?.branch?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>School</td>
                            <td>{response.data?.data?.school?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Address</td>
                            <td>{response.data?.data?.address || ""}</td>
                          </tr>

                          <tr>
                            <td>Standard</td>
                            <td>{response.data?.data?.standard?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Batch</td>
                            <td>{response.data?.data?.batch?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Gender</td>
                            <td>{response.data?.data?.gender?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Profile Photo</td>
                            <td>
                              <a
                                href={`${FILES_URL}/${response.data?.data?.photo}`}
                                target="_blank"
                              >
                                <Button size="sm" variant="success">
                                  VIEW
                                </Button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>Is Active</td>
                            <td>
                              {response.data?.data?.isActive ? "Yes" : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Created At</td>
                            <td>
                              {moment(response.data?.data?.createdAt).format(
                                "DD-MM-YYYY H:mm A"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>

        {false && (
          <div>
            <Container
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Card>
                <Card.Header>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "3rem",
                      alignItems: "center",
                    }}
                  >
                    <h6>Academics</h6>

                    {true && (
                      <Button
                        size="sm"
                        onClick={() =>
                          navigate(
                            `/student?action=add&&type=academic&&id=${parsed.id}`
                          )
                        }
                      >
                        ADD
                      </Button>
                    )}
                  </div>
                </Card.Header>

                <Card.Body>
                  <div>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>School</th>
                          <th>Standard</th>
                          <th>Year</th>
                          <th>Percentage</th>
                          <th>Grade</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentAcademicsResponse &&
                          studentAcademicsResponse.ok &&
                          studentAcademicsResponse.data &&
                          studentAcademicsResponse?.data?.data?.map(
                            (item, index) => {
                              return (
                                <tr key={item._id}>
                                  <td>{index + 1}</td>
                                  <td>{item?.school?.name || ""}</td>
                                  <td>{item?.standard?.name || ""}</td>
                                  <td>{item?.year?.name || ""}</td>
                                  <td>{item?.percentage || ""}</td>
                                  <td>{item?.grade || ""}</td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "1rem",
                                      }}
                                    >
                                      {false && (
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            navigate(
                                              `/student?action=view&&id=${item._id}`
                                            )
                                          }
                                          variant="success"
                                        >
                                          View
                                        </Button>
                                      )}
                                      {true && (
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            navigate(
                                              `/student?action=update&&type=academic&&id=${item._id}`
                                            )
                                          }
                                        >
                                          UPDATE
                                        </Button>
                                      )}
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText:
                                              "Yes, delete it!",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              handleStudentAcademicDelete(
                                                item._id
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        DELETE
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        )}

        {false && (
          <div>
            <Container
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Card>
                <Card.Header>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "3rem",
                      alignItems: "center",
                    }}
                  >
                    <h6>Family</h6>

                    {true && (
                      <Button
                        size="sm"
                        onClick={() =>
                          navigate(
                            `/student?action=add&&type=family&&id=${parsed.id}`
                          )
                        }
                      >
                        ADD
                      </Button>
                    )}
                  </div>
                </Card.Header>

                <Card.Body>
                  <div>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Relation</th>
                          <th>Occupation</th>
                          <th>Contact 1</th>
                          <th>Contact 2</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentParentsResponse &&
                          studentParentsResponse.ok &&
                          studentParentsResponse.data &&
                          studentParentsResponse?.data?.data?.map(
                            (item, index) => {
                              return (
                                <tr key={item._id}>
                                  <td>{index + 1}</td>
                                  <td>{item?.name || ""}</td>
                                  <td>{item?.relation?.name || ""}</td>
                                  <td>{item?.occupation || ""}</td>
                                  <td>{item?.contact1 || ""}</td>
                                  <td>{item?.contact2 || ""}</td>
                                  <td>{item?.email || ""}</td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "1rem",
                                      }}
                                    >
                                      {false && (
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            navigate(
                                              `/student?action=view&&id=${item._id}`
                                            )
                                          }
                                          variant="success"
                                        >
                                          View
                                        </Button>
                                      )}
                                      {true && (
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            navigate(
                                              `/student?action=update&&type=family&&id=${item._id}`
                                            )
                                          }
                                        >
                                          UPDATE
                                        </Button>
                                      )}
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText:
                                              "Yes, delete it!",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              handleStudentFamilyDelete(
                                                item._id
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        DELETE
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        )}
      </AppProvider>
    </div>
  );
};

export default Student;
