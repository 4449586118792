import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useFetch } from "use-http";
import { utils, writeFile } from "xlsx";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import { GetAllBranchUserRoot, ReportRoot } from "../../utils/types";

export const AttendanceReport = () => {
  const [formData, setFormData] = useState<{
    _id: string;
    fromTime: string;
    endTime: string;
  }>({
    _id: "",
    fromTime: "",
    endTime: "",
  });

  const [key, setKey] = useState(Math.random());

  const { post, response } = useFetch<ReportRoot>(
    "/branchUserAttendance/report"
  );
  const { get, response: branchUser } =
    useFetch<GetAllBranchUserRoot>("/branchUser");

  const [refreshing, setRefreshing] = useState(true);

  const [totalHours, setTotalHours] = useState("");
  const [totalMinutes, setTotalMinutes] = useState("");

  useEffect(() => {
    (async () => await fetchData())();
    fetchData();
  }, []);

  const fetchData = async () => {
    setRefreshing(true);

    try {
      await get();

      setRefreshing(false);

      setKey(Math.random());
    } catch (err) {
      setRefreshing(false);
      alert(err);
    }
  };

  const handleSubmit = async (values: {
    id: string;
    fromDate: string;
    toDate: string;
  }) => {
    try {
      setFormData({
        _id: values.id,
        endTime: values.toDate,
        fromTime: values.fromDate,
      });

      setRefreshing(true);

      await post("/monthly", { ...values }).then((res) => {
        let hours = 0;
        let minutes = 0;

        res.data.map((item) => {
          if (item.inTime && item.outTime) {
            let newFromTime = moment(item.date + "T" + item.inTime);
            let newToTime = moment(item.date + "T" + item.outTime);

            hours = hours + newToTime.diff(newFromTime, "hours");
            minutes = minutes + newToTime.diff(newFromTime, "minutes");
          }
        });

        hours = hours + Math.floor(minutes / 60);
        minutes = minutes % 60;

        setTotalHours(hours.toString());
        setTotalMinutes(minutes.toString());
      });

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      alert(err);
    }
  };

  const handleExcelReport = async () => {
    const isExist = response.data?.data.find(
      (x) => x.branchUser._id === formData._id
    );

    if (isExist) {
      const arrData: string[][] = [];

      const headingArray: string[] = [
        "NAME",
        "DATE",
        "BRANCH",
        "FROM",
        "TO",
        "HOUR",
        "MINUTE",
      ];

      arrData.push(headingArray);

      let hours = 0;
      let minutes = 0;

      response?.data?.data?.map((item) => {
        const headingArray: string[] = [];

        if (item.inTime && item.outTime) {
          let newFromTime = moment(item.date + "T" + item.inTime);
          let newToTime = moment(item.date + "T" + item.outTime);

          hours = hours + newToTime.diff(newFromTime, "hours");
          minutes = minutes + newToTime.diff(newFromTime, "minutes");

          headingArray.push(
            item?.branchUser?.name || "",
            item?.date || "",
            item?.branch?.name || "",
            item?.inTime,
            item?.outTime,
            hours.toString(),
            minutes.toString()
          );

          arrData.push(headingArray);
        }
      });

      hours = hours + Math.floor(minutes / 60);
      minutes = minutes % 60;

      const newHeadingArray = [
        "Total",
        "",
        "",
        "",
        "",
        hours.toString(),
        minutes.toString(),
      ];

      arrData.push(newHeadingArray);

      const workbook = utils.book_new();
      const worksheet = utils.aoa_to_sheet(arrData);
      utils.book_append_sheet(workbook, worksheet);

      writeFile(
        workbook,
        isExist?.branchUser?.name +
          "AttendanceReport" +
          Date.now().toString() +
          ".xlsx"
      );
    } else {
      toast.error("Please submit form to get data");
    }
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Branch Admin Report</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    id: Yup.string().required("Please select branch"),
                    fromDate: Yup.string().required("Please select from date"),
                    toDate: Yup.string().required("Please select to date"),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    id: "",
                    fromDate: "",
                    toDate: "",
                  }}
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch User <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="id"
                            onChange={handleChange}
                            value={values.id}
                            isInvalid={!!touched.id && !!errors.id}
                          >
                            <option value={""}>Select</option>

                            {branchUser.data?.data.map((item) => (
                              <option value={item._id}>{item.name}</option>
                            ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.id}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            From Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="fromDate"
                            value={values.fromDate}
                            onChange={handleChange}
                            isValid={touched.fromDate && !errors.fromDate}
                            isInvalid={!!touched.fromDate && !!errors.fromDate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromDate}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            To Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="toDate"
                            value={values.toDate}
                            onChange={handleChange}
                            isValid={touched.toDate && !errors.toDate}
                            isInvalid={!!touched.toDate && !!errors.toDate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toDate}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Branch Admin Report Data</h5>
                  <Button variant="success" onClick={handleExcelReport}>
                    Excel
                  </Button>
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Branch</th>
                      <th>From time</th>
                      <th>To time</th>
                      <th>HH:MM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response.data?.data.map((item, index: number) => (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item?.branchUser?.name || ""}</td>
                        <td>{item?.date || ""}</td>
                        <td>{item?.branch?.name || ""}</td>
                        <td>{item?.inTime}</td>
                        <td>{item?.outTime}</td>
                        <td>
                          <CalculateHours
                            date={item?.date}
                            fromTime={item?.inTime || ""}
                            toTime={item?.outTime || ""}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={6}>Total Time</th>
                      <th>
                        {totalHours ? (
                          <>
                            {totalHours}:{totalMinutes}
                          </>
                        ) : (
                          <></>
                        )}
                      </th>
                    </tr>
                  </tfoot>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const CalculateHours: React.FC<{
  fromTime: string;
  toTime: string;
  date: string;
}> = ({ fromTime, toTime, date }) => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");

  useEffect(() => {
    if (fromTime && toTime) {
      let newFromTime = moment(date + "T" + fromTime);
      let newToTime = moment(date + "T" + toTime);

      setHours(newToTime.diff(newFromTime, "hours").toString());
      setMinutes((newToTime.diff(newFromTime, "minutes") % 60).toString());
    }
  }, []);

  return (
    <div>
      {hours ? (
        <>
          {hours}:{minutes}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
