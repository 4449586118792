export const API_URL = "https://connect.scpledu.com/api";
export const FILES_URL = "https://connect.scpledu.com/uploads";

// export const API_URL = "http://localhost:4000/api";
// export const FILES_URL = "http://localhost:4000/uploads";

// export const API_URL =
//   "https://coupon-scholar-date-markets.trycloudflare.com/api";
// export const FILES_URL =
//   "https://coupon-scholar-date-markets.trycloudflare.com/uploads";
