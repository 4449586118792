import { FC } from "react";
import { Col, Form } from "react-bootstrap";

export const CustomMultiSelect: FC<{
  data: { label: string; value: string }[];
  setFieldValue: any;
  name: string;
  label: string;
  isRequired: boolean;
  value: string[];
  handleChange: any;
}> = ({
  data,
  name,
  setFieldValue,
  label,
  isRequired,
  value,
  handleChange,
}) => {
  return (
    <Form.Group as={Col} md="4" className="mb-3">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>

      {data.map((x) => (
        <Form.Check
          key={x.value}
          name={name}
          label={x.label}
          value={x.value}
          onChange={handleChange}
          defaultChecked={value.includes(x.value) ? true : false}
        />
      ))}
    </Form.Group>
  );
};
