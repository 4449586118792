import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import branchReducer from "./branchReducer";
import lecturePlanningMatrixReducer from "./lecturePlanningMatrixReducer";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    branchReducer: branchReducer,
    lecturePlanningMatrixReducer: lecturePlanningMatrixReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
