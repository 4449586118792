import { asyncMap } from "modern-async";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Form, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useFetch } from "use-http";
import { utils, writeFile } from "xlsx";
import AppProvider from "../../components/AppProvider";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearLecturePlanningMatrixState,
  LecturePlanningMatrixState,
  resetLecturePlanningMatrixState,
  setLecturePlanningMatrixState,
} from "../../store/lecturePlanningMatrixReducer";
import {
  AddDesignationRoot,
  BranchDistanceTimeDaum,
  BranchDistanceTimeRoot,
  GetAllDesignationAPIRoot,
  ManageBranchRoot,
  Teacher,
} from "../../utils/types";

const FIRST_HALF_DATA: {
  _id: string;
  fromTime: string;
  toTime: string;
  timeSlot: string;
}[] = [
  {
    _id: "1",
    fromTime: "07:00",
    toTime: "08:00",
    timeSlot: "7-8",
  },
  {
    _id: "2",
    fromTime: "08:00",
    toTime: "09:00",
    timeSlot: "8-9",
  },
  {
    _id: "3",
    fromTime: "09:00",
    toTime: "10:00",
    timeSlot: "9-10",
  },
  {
    _id: "4",
    fromTime: "10:00",
    toTime: "11:00",
    timeSlot: "10-11",
  },
  {
    _id: "5",
    fromTime: "11:00",
    toTime: "12:00",
    timeSlot: "11-12",
  },
  {
    _id: "6",
    fromTime: "12:00",
    toTime: "13:00",
    timeSlot: "12-1",
  },
];

const SECOND_HALF_DATA: {
  _id: string;
  fromTime: string;
  toTime: string;
  timeSlot: string;
}[] = [
  {
    _id: "1",
    fromTime: "03:00",
    toTime: "04:00",
    timeSlot: "3-4",
  },
  {
    _id: "2",
    fromTime: "04:00",
    toTime: "05:00",
    timeSlot: "4-5",
  },
  {
    _id: "3",
    fromTime: "05:00",
    toTime: "06:00",
    timeSlot: "5-6",
  },
  {
    _id: "4",
    fromTime: "06:00",
    toTime: "07:00",
    timeSlot: "6-7",
  },
];

const CustomKeyboard = () => {
  const [option, setOption] = useState<string>("0");

  return (
    <div>
      <AppProvider>
        <Container fluid>
          <Card
            style={{
              width: "95%",
              margin: "50px",
              padding: 10,
            }}
          >
            <Form>
              <Form.Check
                inline
                type="radio"
                label={"First Half"}
                name="slot"
                onChange={(e) => setOption(e.target.value)}
                value={"0"}
                defaultChecked={option === "0" ? true : false}
              />
              <Form.Check
                inline
                type="radio"
                label={"Second Half"}
                name="slot"
                onChange={(e) => setOption(e.target.value)}
                value={"1"}
                defaultChecked={option === "1" ? true : false}
              />
            </Form>
          </Card>
        </Container>

        <div>{option === "0" ? <FirstHalf /> : <SecondHalf />}</div>
      </AppProvider>
    </div>
  );
};

const FirstHalf = () => {
  //Part 1

  const [dataArray, setDataArray] = useState<string[][]>([]);
  const myArray: number[][] = Array(FIRST_HALF_DATA.length).fill(
    Array(20).fill(1)
  );
  const [activeCell, setActiveCell] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [columnIndex, setColumnIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [arrowKey, setArrowKey] = useState(0);

  //Part 2
  const [option, setOption] = useState("0");
  const [batchId, setBatchId] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { post: addLecture } = useFetch<AddDesignationRoot>("/lecture");
  const { post: addTest } = useFetch<AddDesignationRoot>("/test");
  const { get, response } = useFetch<ManageBranchRoot>("/manageBranch");
  const { get: getBDT, response: branchDistanceTimeResponse } =
    useFetch<BranchDistanceTimeRoot>("/branchDistanceTime");
  const lecturePlanningMatrixData = useAppSelector(
    (state) => state.lecturePlanningMatrixReducer
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    dispatch(clearLecturePlanningMatrixState());
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await get();
      await getBatch().then(
        (res) => res.success && setBatchId(res?.data[0]?._id)
      );
      await getBDT();
      setKey(Math.random());
      setLoading(false);
    } catch (err) {
      setLoading(false);

      alert(err);
    }
  };

  useEffect(() => {
    let temp: string[][] = [];

    if (response.data) {
      FIRST_HALF_DATA.map((x, xi) => {
        let tempTwo: string[] = [];

        response.data?.data
          ?.filter((y) => y.branch.isFirstHalf)
          .map((y, yi) => {
            y.firstHalfStandard.map((z, zi) => {
              tempTwo.push("" + xi + yi + zi);
            });
          });

        temp.push(tempTwo);
      });
    }

    setDataArray(temp);
  }, [response.data]);

  useEffect(() => {
    if (activeCell === "") {
      setRowIndex(0);
      setColumnIndex(0);
      setActiveCell("" + 0 + 0 + 0);
    } else {
      //Down
      if (arrowKey === 1) {
        if (rowIndex < FIRST_HALF_DATA.length - 1) {
          let cellIndex = dataArray[rowIndex + 1][columnIndex];

          setActiveCell(cellIndex);

          setRowIndex((rowIndex) => rowIndex + 1);
        }
      }

      //Right
      else if (arrowKey === 2) {
        if (columnIndex < dataArray[0].length - 1) {
          let cellIndex = dataArray[rowIndex][columnIndex + 1];

          setActiveCell(cellIndex);

          setColumnIndex((columnIndex) => columnIndex + 1);
        }
      }

      //Up
      else if (arrowKey === 3) {
        if (rowIndex > 0) {
          let cellIndex = dataArray[rowIndex - 1][columnIndex];

          setActiveCell(cellIndex);

          setRowIndex((rowIndex) => rowIndex - 1);
        }
      }

      //Left
      else if (arrowKey === 4) {
        if (columnIndex > 0) {
          let cellIndex = dataArray[rowIndex][columnIndex - 1];

          setActiveCell(cellIndex);

          setColumnIndex((columnIndex) => columnIndex - 1);
        }
      }
    }
  }, [count]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (
      e.key === "ArrowDown" ||
      e.key === "ArrowRight" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowLeft"
    ) {
      setCount((count) => count + 1);

      if (e.key === "ArrowDown") setArrowKey(1);
      else if (e.key === "ArrowRight") setArrowKey(2);
      else if (e.key === "ArrowUp") setArrowKey(3);
      else if (e.key === "ArrowLeft") setArrowKey(4);
    }
  };

  const handlePrevieExcel = async () => {
    const arrData: string[][] = [];

    const headingArray: string[] = ["Time"];

    response.data?.data
      .filter((y) => y.branch.isFirstHalf)
      .map((y) => {
        y.firstHalfStandard.map((z) => {
          headingArray.push(z.name + " " + y.branch.abbreviation);
        });
      });

    arrData.push(headingArray);

    FIRST_HALF_DATA.map((x) => {
      const headingArray: string[] = [x.timeSlot];

      response.data?.data
        .filter((y) => y.branch.isFirstHalf)
        .map((y) => {
          y.firstHalfStandard.map((z) => {
            let temp = lecturePlanningMatrixData.data.find(
              (w) => w._id === x._id + y._id + z._id
            );

            if (temp) {
              if (temp.isSchool) {
                headingArray.push("S");
              } else {
                let findTeacher = y.teacher.find(
                  (a) => a.teacher._id === temp?.teacher
                );

                if (findTeacher) {
                  if (temp.isLecture) {
                    headingArray.push(
                      "L" +
                        " " +
                        findTeacher.teacher.abbreviation +
                        " " +
                        findTeacher.subject.find((x) => x._id === temp?.subject)
                          ?.abbreviation
                    );
                  } else {
                    headingArray.push(
                      "T" +
                        " " +
                        findTeacher.teacher.abbreviation +
                        " " +
                        findTeacher.subject.find((x) => x._id === temp?.subject)
                          ?.abbreviation
                    );
                  }
                }
              }
            } else {
              headingArray.push("");
            }
          });
        });

      arrData.push(headingArray);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(workbook, "export" + Date.now().toString() + ".xlsx");
  };

  const handleSubmit = async () => {
    try {
      if (
        lecturePlanningMatrixData &&
        lecturePlanningMatrixData.data &&
        lecturePlanningMatrixData.data.length !== 0
      ) {
        setLoading(true);

        await asyncMap(lecturePlanningMatrixData.data, async (x) => {
          if (x.isLecture) {
            await addLecture(x);
          } else if (!x.isLecture && !x.isSchool) {
            await addTest(x);
          }
        });

        setLoading(false);
        dispatch(clearLecturePlanningMatrixState());
      }
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  if (loading) {
    return <div>Wait...</div>;
  }

  return (
    <div>
      <Container fluid>
        <div
          key={key}
          style={{
            width: "95%",
            margin: "50px",
          }}
        >
          <div>
            <ul
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <button onClick={() => setShow(true)}>Preview</button>
                <button onClick={handleSubmit}>Save</button>
              </div>
            </ul>
          </div>

          <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <ul
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <span>{moment().format("DD/MM/YYYY")}</span>
                  <button onClick={handlePrevieExcel}>Excel</button>
                </ul>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <td rowSpan={2}>Time</td>
                    {response.data?.data
                      ?.filter((x) => x.branch.isFirstHalf)
                      .map((x) => {
                        return (
                          <td colSpan={x.firstHalfStandard.length}>
                            {x.branch.name}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    {response.data?.data
                      ?.filter((x) => x.branch.isFirstHalf)
                      .map((x) =>
                        x.firstHalfStandard.map((y) => {
                          return <td>{y.name}</td>;
                        })
                      )}
                  </tr>
                </thead>
                <tbody>
                  {FIRST_HALF_DATA.map((x) => {
                    return (
                      <tr>
                        <td>{x.timeSlot}</td>
                        {response.data?.data
                          ?.filter((y) => y.branch.isFirstHalf)
                          .map((y) =>
                            y.firstHalfStandard.map((z) => {
                              return (
                                <td>
                                  <div
                                    style={{
                                      width: 100,
                                    }}
                                  >
                                    <DisplayTeacher
                                      _id={x._id + y._id + z._id}
                                      lecturePlanningMatrixData={
                                        lecturePlanningMatrixData.data
                                      }
                                      teachers={y.teacher}
                                      branch={y.branch._id}
                                      standard={z._id}
                                    />
                                  </div>
                                </td>
                              );
                            })
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>

          <Table bordered responsive>
            <thead>
              <tr>
                <td>Time</td>
                {response.data?.data
                  ?.filter((x) => x.branch.isFirstHalf)
                  .map((x) => {
                    return (
                      <td colSpan={x.firstHalfStandard.length}>
                        {x.branch.name}
                      </td>
                    );
                  })}
              </tr>
              <tr>
                <td></td>
                {response.data?.data
                  ?.filter((x) => x.branch.isFirstHalf)
                  .map((x) =>
                    x.firstHalfStandard.map((y) => {
                      return <td>{y.name}</td>;
                    })
                  )}
              </tr>
            </thead>

            <tbody>
              {FIRST_HALF_DATA.map((x, xi) => {
                return (
                  <tr>
                    <td>{x.timeSlot}</td>

                    {response.data?.data
                      .filter((y) => y.branch.isFirstHalf)
                      .map((y, yi) =>
                        y.firstHalfStandard.map((z, zi) => {
                          return (
                            <td>
                              <CustomDiv
                                focusId={"" + xi + yi + zi}
                                activeCell={activeCell}
                                _id={x._id + y._id + z._id}
                                teachers={y.teacher}
                                fromTime={x.fromTime}
                                toTime={x.toTime}
                                timeId={x._id}
                                branchDistanceTimeData={
                                  branchDistanceTimeResponse.data?.data || []
                                }
                                branch={y.branch._id}
                                standard={z._id}
                                date={selectedDate}
                                batch={batchId}
                              />
                            </td>
                          );
                        })
                      )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  );
};

const SecondHalf = () => {
  //Part 1

  const [dataArray, setDataArray] = useState<string[][]>([]);
  const myArray: number[][] = Array(SECOND_HALF_DATA.length).fill(
    Array(20).fill(1)
  );
  const [activeCell, setActiveCell] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [columnIndex, setColumnIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [arrowKey, setArrowKey] = useState(0);

  //Part 2
  const [option, setOption] = useState("0");
  const [batchId, setBatchId] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { post: addLecture } = useFetch<AddDesignationRoot>("/lecture");
  const { post: addTest } = useFetch<AddDesignationRoot>("/test");
  const { get, response } = useFetch<ManageBranchRoot>("/manageBranch");
  const { get: getBDT, response: branchDistanceTimeResponse } =
    useFetch<BranchDistanceTimeRoot>("/branchDistanceTime");
  const lecturePlanningMatrixData = useAppSelector(
    (state) => state.lecturePlanningMatrixReducer
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    dispatch(clearLecturePlanningMatrixState());
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await get();
      await getBatch().then(
        (res) => res.success && setBatchId(res?.data[0]?._id)
      );
      await getBDT();
      setKey(Math.random());
      setLoading(false);
    } catch (err) {
      setLoading(false);

      alert(err);
    }
  };

  useEffect(() => {
    let temp: string[][] = [];

    if (response.data) {
      SECOND_HALF_DATA.map((x, xi) => {
        let tempTwo: string[] = [];

        response.data?.data
          ?.filter((y) => y.branch.isSecondHalf)
          .map((y, yi) => {
            y.secondHalfStandard.map((z, zi) => {
              tempTwo.push("" + xi + yi + zi);
            });
          });

        temp.push(tempTwo);
      });
    }

    setDataArray(temp);
  }, [response.data]);

  useEffect(() => {
    if (activeCell === "") {
      setRowIndex(0);
      setColumnIndex(0);
      setActiveCell("" + 0 + 0 + 0);
    } else {
      //Down
      if (arrowKey === 1) {
        if (rowIndex < SECOND_HALF_DATA.length - 1) {
          let cellIndex = dataArray[rowIndex + 1][columnIndex];

          setActiveCell(cellIndex);

          setRowIndex((rowIndex) => rowIndex + 1);
        }
      }

      //Right
      else if (arrowKey === 2) {
        if (columnIndex < dataArray[0].length - 1) {
          let cellIndex = dataArray[rowIndex][columnIndex + 1];

          setActiveCell(cellIndex);

          setColumnIndex((columnIndex) => columnIndex + 1);
        }
      }

      //Up
      else if (arrowKey === 3) {
        if (rowIndex > 0) {
          let cellIndex = dataArray[rowIndex - 1][columnIndex];

          setActiveCell(cellIndex);

          setRowIndex((rowIndex) => rowIndex - 1);
        }
      }

      //Left
      else if (arrowKey === 4) {
        if (columnIndex > 0) {
          let cellIndex = dataArray[rowIndex][columnIndex - 1];

          setActiveCell(cellIndex);

          setColumnIndex((columnIndex) => columnIndex - 1);
        }
      }
    }
  }, [count]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (
      e.key === "ArrowDown" ||
      e.key === "ArrowRight" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowLeft"
    ) {
      setCount((count) => count + 1);

      if (e.key === "ArrowDown") setArrowKey(1);
      else if (e.key === "ArrowRight") setArrowKey(2);
      else if (e.key === "ArrowUp") setArrowKey(3);
      else if (e.key === "ArrowLeft") setArrowKey(4);
    }
  };

  const handlePrevieExcel = async () => {
    const arrData: string[][] = [];

    const headingArray: string[] = ["Time"];

    response.data?.data
      .filter((y) => y.branch.isSecondHalf)
      .map((y) => {
        y.secondHalfStandard.map((z) => {
          headingArray.push(z.name + " " + y.branch.abbreviation);
        });
      });

    arrData.push(headingArray);

    SECOND_HALF_DATA.map((x) => {
      const headingArray: string[] = [x.timeSlot];

      response.data?.data
        .filter((y) => y.branch.isSecondHalf)
        .map((y) => {
          y.secondHalfStandard.map((z) => {
            let temp = lecturePlanningMatrixData.data.find(
              (w) => w._id === x._id + y._id + z._id
            );

            if (temp) {
              if (temp.isSchool) {
                headingArray.push("S");
              } else {
                let findTeacher = y.teacher.find(
                  (a) => a.teacher._id === temp?.teacher
                );

                if (findTeacher) {
                  if (temp.isLecture) {
                    headingArray.push(
                      "L" +
                        " " +
                        findTeacher.teacher.abbreviation +
                        " " +
                        findTeacher.subject.find((x) => x._id === temp?.subject)
                          ?.abbreviation
                    );
                  } else {
                    headingArray.push(
                      "T" +
                        " " +
                        findTeacher.teacher.abbreviation +
                        " " +
                        findTeacher.subject.find((x) => x._id === temp?.subject)
                          ?.abbreviation
                    );
                  }
                }
              }
            } else {
              headingArray.push("");
            }
          });
        });

      arrData.push(headingArray);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(workbook, "export" + Date.now().toString() + ".xlsx");
  };

  const handleSubmit = async () => {
    try {
      if (
        lecturePlanningMatrixData &&
        lecturePlanningMatrixData.data &&
        lecturePlanningMatrixData.data.length !== 0
      ) {
        setLoading(true);

        await asyncMap(lecturePlanningMatrixData.data, async (x) => {
          if (x.isLecture) {
            await addLecture(x);
          } else if (!x.isLecture && !x.isSchool) {
            await addTest(x);
          }
        });

        setLoading(false);
        dispatch(clearLecturePlanningMatrixState());
      }
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  if (loading) {
    return <div>Wait...</div>;
  }

  return (
    <div>
      <Container fluid>
        <div
          key={key}
          style={{
            width: "95%",
            margin: "50px",
          }}
        >
          <div>
            <ul
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <button onClick={() => setShow(true)}>Preview</button>
                <button onClick={handleSubmit}>Save</button>
              </div>
            </ul>
          </div>

          <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <ul
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <span>{moment().format("DD/MM/YYYY")}</span>
                  <button onClick={handlePrevieExcel}>Excel</button>
                </ul>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <td rowSpan={2}>Time</td>
                    {response.data?.data
                      ?.filter((x) => x.branch.isSecondHalf)
                      .map((x) => {
                        return (
                          <td colSpan={x.secondHalfStandard.length}>
                            {x.branch.name}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    {response.data?.data
                      ?.filter((x) => x.branch.isSecondHalf)
                      .map((x) =>
                        x.secondHalfStandard.map((y) => {
                          return <td>{y.name}</td>;
                        })
                      )}
                  </tr>
                </thead>
                <tbody>
                  {SECOND_HALF_DATA.map((x) => {
                    return (
                      <tr>
                        <td>{x.timeSlot}</td>
                        {response.data?.data
                          ?.filter((y) => y.branch.isSecondHalf)
                          .map((y) =>
                            y.secondHalfStandard.map((z) => {
                              return (
                                <td>
                                  <div
                                    style={{
                                      width: 100,
                                    }}
                                  >
                                    <DisplayTeacher
                                      _id={x._id + y._id + z._id}
                                      lecturePlanningMatrixData={
                                        lecturePlanningMatrixData.data
                                      }
                                      teachers={y.teacher}
                                      branch={y.branch._id}
                                      standard={z._id}
                                    />
                                  </div>
                                </td>
                              );
                            })
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>

          <Table bordered responsive>
            <thead>
              <tr>
                <td>Time</td>
                {response.data?.data
                  ?.filter((x) => x.branch.isSecondHalf)
                  .map((x) => {
                    return (
                      <td colSpan={x.secondHalfStandard.length}>
                        {x.branch.name}
                      </td>
                    );
                  })}
              </tr>
              <tr>
                <td></td>
                {response.data?.data
                  ?.filter((x) => x.branch.isSecondHalf)
                  .map((x) =>
                    x.secondHalfStandard.map((y) => {
                      return <td>{y.name}</td>;
                    })
                  )}
              </tr>
            </thead>

            <tbody>
              {SECOND_HALF_DATA.map((x, xi) => {
                return (
                  <tr>
                    <td>{x.timeSlot}</td>

                    {response.data?.data
                      ?.filter((x) => x.branch.isSecondHalf)
                      .map((y, yi) =>
                        y.secondHalfStandard.map((z, zi) => {
                          return (
                            <td>
                              <CustomDiv
                                focusId={"" + xi + yi + zi}
                                activeCell={activeCell}
                                _id={x._id + y._id + z._id}
                                teachers={y.teacher}
                                fromTime={x.fromTime}
                                toTime={x.toTime}
                                timeId={x._id}
                                branchDistanceTimeData={
                                  branchDistanceTimeResponse.data?.data || []
                                }
                                branch={y.branch._id}
                                standard={z._id}
                                date={selectedDate}
                                batch={batchId}
                              />
                            </td>
                          );
                        })
                      )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  );
};

const CustomDiv: React.FC<{
  focusId: string;
  activeCell: string;

  _id: string;
  timeId: string;
  fromTime: string;
  toTime: string;
  branch: string;
  teachers: Teacher[];
  branchDistanceTimeData: BranchDistanceTimeDaum[];
  standard: string;
  date: string;
  batch: string;
}> = ({
  activeCell,
  focusId,

  teachers,
  _id,
  fromTime,
  toTime,
  timeId,
  branchDistanceTimeData,
  branch,
  standard,
  date,
  batch,
}) => {
  const textInput = useRef<HTMLInputElement>(null);
  const [text, setText] = useState("");

  const [dtData, setDtData] = useState<{
    isDisplay: boolean;
    distance: string;
    time: string;
  }>({
    isDisplay: false,
    distance: "",
    time: "",
  });
  const lecturePlanningMatrixData = useAppSelector(
    (state) => state.lecturePlanningMatrixReducer
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    textInput.current?.focus();
  }, [activeCell]);

  const checkIsExistBefore = (name: string): boolean => {
    //Find Teacher

    let findTeacher = teachers.find(
      (y) => y.teacher.abbreviation.toLowerCase() === name.toLowerCase()
    );

    //CHeck Teacher exist or not
    if (findTeacher) {
      //Check for given time slot

      let isTecherExistBeforeInThisTimeSlot =
        lecturePlanningMatrixData.data.find(
          (x) => x.timeId === timeId && x.teacher === findTeacher?.teacher._id
        );

      //Teacher has lecture already for given time slot
      if (isTecherExistBeforeInThisTimeSlot) {
        if (isTecherExistBeforeInThisTimeSlot._id !== _id) {
          // alert("Exist before");

          toast.error("Already exist");

          return true;
        }
      }

      //Check if exist in other time slot or before

      let isTecherExistBeforeInOtherTimeSlot =
        lecturePlanningMatrixData.data.find(
          (x) => x.teacher === findTeacher?.teacher._id
        );

      //Teacher exist in other time slot

      if (isTecherExistBeforeInOtherTimeSlot) {
        let lastRecordOfThisTeacher = lecturePlanningMatrixData.data
          .filter((x) => x.teacher === findTeacher?.teacher._id)
          .sort((a, b) => {
            if (a.fromTime.toLowerCase() < b.fromTime.toLowerCase()) return -1;
            if (a.fromTime.toLowerCase() > b.fromTime.toLowerCase()) return 1;
            return 0;
          })
          .reverse()
          .map((x) => {
            if (x.fromTime < fromTime && x.toTime < toTime) return x;
          })
          .reverse()
          .pop();

        if (lastRecordOfThisTeacher) {
          // console.log(lastRecordOfThisTeacher);
          // calculateDitanceAndTimeToReachNextCenter(
          //   lastRecordOfThisTeacher.branch
          // );
        }
      }
    }

    return false;
  };

  const calculateDitanceAndTimeToReachNextCenter = (from: string) => {
    let distanceAndTimeToReachNextCenter = branchDistanceTimeData.find(
      (x) => x.from === from && x.to === branch
    );

    if (distanceAndTimeToReachNextCenter) {
      setDtData({
        distance: distanceAndTimeToReachNextCenter.distance,
        isDisplay: true,
        time: distanceAndTimeToReachNextCenter.time,
      });
    }
  };

  const handleBlur = (value: string) => {
    if (value) {
      let newData = value.split(" ");

      if (
        newData[0].toLowerCase() === "l" ||
        newData[0].toLowerCase() === "t" ||
        newData[0].toLowerCase() === "s"
      ) {
        if (newData[0].toLowerCase() === "s") {
          dispatch(
            setLecturePlanningMatrixState({
              _id: _id,
              subject: "",
              teacher: "",
              fromTime: fromTime,
              toTime: toTime,
              timeId: timeId,
              branch: branch,
              standard: standard,
              isLecture: false,
              isSchool: true,
              date: date,
              batch,
            })
          );
        } else {
          if (newData[1]) {
            let isTeacherExist = teachers.find(
              (x) =>
                x.teacher.abbreviation.toLowerCase() ===
                newData[1].toLowerCase()
            );

            if (isTeacherExist) {
              let isExist = false;

              if (newData[0].toLowerCase() === "l") {
                isExist = checkIsExistBefore(newData[1]);
              }

              if (isExist) {
                setText("");
              } else {
                if (newData[2]) {
                  let isSubjectExist = teachers
                    .find(
                      (x) =>
                        x.teacher.abbreviation.toLowerCase() ===
                        newData[1].toLowerCase()
                    )
                    ?.subject.find(
                      (x) =>
                        x.abbreviation.toLowerCase() ===
                        newData[2].toLowerCase()
                    );

                  if (isSubjectExist) {
                    dispatch(
                      setLecturePlanningMatrixState({
                        _id: _id,
                        subject: isSubjectExist._id,
                        teacher: isTeacherExist.teacher._id || "",
                        fromTime: fromTime,
                        toTime: toTime,
                        timeId: timeId,
                        branch: branch,
                        standard: standard,
                        isLecture:
                          newData[0].toLowerCase() === "l" ? true : false,
                        isSchool: false,
                        date: date,
                        batch,
                      })
                    );
                  } else {
                    toast.error("This subject doesn't exist");
                  }
                } else {
                  toast.error("Please enter subject");
                }
              }
            } else {
              toast.error("Teacher doesn't exist");
            }
          } else {
            toast.error("Please enter teacher & subject");
          }
        }
      } else {
        toast.error("You can add only L, T & S");
      }
    } else {
      dispatch(resetLecturePlanningMatrixState({ _id: _id }));
    }
  };

  return (
    <div key={focusId}>
      <input
        ref={activeCell === focusId ? textInput : null}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        style={{
          width: 100,
        }}
      />
    </div>
  );
};

const DisplayTeacher: React.FC<{
  _id: string;
  teachers: Teacher[];
  lecturePlanningMatrixData: LecturePlanningMatrixState[];
  branch: string;
  standard: string;
}> = ({ branch, lecturePlanningMatrixData, standard, teachers, _id }) => {
  const [teacherName, setTeacherName] = useState("");
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    let isRecordExist = lecturePlanningMatrixData.find((x) => x._id === _id);

    if (isRecordExist) {
      if (isRecordExist.isSchool) {
        setTeacherName("S");
      } else {
        let isTeacherExist = teachers.find(
          (x) => x.teacher._id === isRecordExist?.teacher
        );

        if (isTeacherExist) {
          if (isRecordExist.isLecture)
            setTeacherName(
              "L" +
                " " +
                isTeacherExist.teacher.abbreviation +
                " " +
                isTeacherExist.subject.find(
                  (x) => x._id === isRecordExist?.subject
                )?.abbreviation
            );
          else
            setTeacherName(
              "T" +
                " " +
                isTeacherExist.teacher.abbreviation +
                " " +
                isTeacherExist.subject.find(
                  (x) => x._id === isRecordExist?.subject
                )?.abbreviation
            );
        }
      }
    }
  }, [_id]);

  return <div key={key}>{teacherName}</div>;
};

export default CustomKeyboard;
