import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useFetch } from "use-http";
import AppProvider from "../components/AppProvider";
import CustomLoader from "../components/CustomLoader";
import "../css/dashboard.css";
import { useAppSelector } from "../store/hooks";
import { DashboardCountRoot } from "../utils/types";

const Dashboard = () => {
  const [refreshing, setRefreshing] = useState(true);
  const branchId = useAppSelector((state) => state.branchReducer._id);

  const { get, response } = useFetch<DashboardCountRoot>("/dashboard");

  useEffect(() => {
    (async () => {
      try {
        setRefreshing(true);

        if (branchId) {
          await get(`/count/${branchId}`).then((res) => {
            if (res)
              if (res.success) {
                setRefreshing(false);
              } else {
                alert(res.message);
                setRefreshing(false);
              }
          });
        } else {
          await get(`/count`).then((res) => {
            if (res)
              if (res.success) {
                setRefreshing(false);
              } else {
                alert(res.message);
                setRefreshing(false);
              }
          });
        }
      } catch (err) {
        alert(err);
        setRefreshing(false);
      }
    })();
  }, [branchId]);

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <CustomLoader />;
        </AppProvider>
      </div>
    );
  }

  if (branchId) {
    return (
      <div>
        <AppProvider>
          <div>
            {response?.data?.success && response.data?.data && (
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                    <Card
                      style={{
                        width: "300px",
                        height: "140px",
                        backgroundColor: "aliceblue",
                        margin: "1rem",
                      }}
                      className="Card-animate overflow-hidden text-black mb-3"
                    >
                      <div
                        className="position-absolute start-0"
                        style={{ zIndex: 0 }}
                      >
                        <svg
                          version="1.2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 120"
                          width="200"
                          height="140"
                        >
                          <path
                            id="Shape 8"
                            className="s0"
                            d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                          ></path>
                        </svg>
                      </div>
                      <Card.Body>
                        <Card.Title>
                          <i className="bi bi-building bg-warning-subtle"></i>{" "}
                          Student
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "rgb(63, 88, 98)",
                          }}
                        >
                          {response.data?.data?.studentCount || 0}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                    <Card
                      style={{
                        width: "300px",
                        height: "140px",
                        backgroundColor: "aliceblue",
                        margin: "1rem",
                      }}
                      className="Card-animate overflow-hidden text-black mb-3"
                    >
                      <div
                        className="position-absolute start-0"
                        style={{ zIndex: 0 }}
                      >
                        <svg
                          version="1.2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 120"
                          width="200"
                          height="140"
                        >
                          <path
                            id="Shape 8"
                            className="s0"
                            d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                          ></path>
                        </svg>
                      </div>
                      <Card.Body>
                        <Card.Title>
                          <i className="bi bi-building bg-warning-subtle"></i>{" "}
                          Lecture
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "rgb(63, 88, 98)",
                          }}
                        >
                          {response.data?.data?.lectureCount || 0}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                    <Card
                      style={{
                        width: "300px",
                        height: "140px",
                        backgroundColor: "aliceblue",
                        margin: "1rem",
                      }}
                      className="Card-animate overflow-hidden text-black mb-3"
                    >
                      <div
                        className="position-absolute start-0"
                        style={{ zIndex: 0 }}
                      >
                        <svg
                          version="1.2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 120"
                          width="200"
                          height="140"
                        >
                          <path
                            id="Shape 8"
                            className="s0"
                            d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                          ></path>
                        </svg>
                      </div>
                      <Card.Body>
                        <Card.Title>
                          <i className="bi bi-building bg-warning-subtle"></i>{" "}
                          Test
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "rgb(63, 88, 98)",
                          }}
                        >
                          {response.data?.data?.testCount || 0}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          {response?.data?.success && response.data?.data && (
            <Container>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-building bg-warning-subtle"></i>{" "}
                        Branches
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.branchCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-person bg-warning-subtle"></i>{" "}
                        Teacher
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.teacherCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-person bg-warning-subtle"></i>{" "}
                        Student
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.studentCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-person bg-warning-subtle"></i>{" "}
                        Parent
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.parentCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-calendar-event bg-warning-subtle"></i>{" "}
                        Lecture
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.lectureCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-building bg-warning-subtle"></i>{" "}
                        Batch
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.batchCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-card-checklist bg-warning-subtle"></i>{" "}
                        Test
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.testCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-journals bg-warning-subtle"></i>{" "}
                        Material
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.materialCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-card-checklist bg-warning-subtle"></i>{" "}
                        Subject
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.subjectCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-building bg-warning-subtle"></i>{" "}
                        School
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.schoolCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                  <Card
                    style={{
                      width: "300px",
                      height: "140px",
                      backgroundColor: "aliceblue",
                      margin: "1rem",
                    }}
                    className="Card-animate overflow-hidden text-black mb-3"
                  >
                    <div
                      className="position-absolute start-0"
                      style={{ zIndex: 0 }}
                    >
                      <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 200 120"
                        width="200"
                        height="140"
                      >
                        <path
                          id="Shape 8"
                          className="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                        ></path>
                      </svg>
                    </div>
                    <Card.Body>
                      <Card.Title>
                        <i className="bi bi-calendar-event bg-warning-subtle"></i>{" "}
                        Appointment
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "rgb(63, 88, 98)",
                        }}
                      >
                        {response.data?.data?.appointmetCount || 0}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </AppProvider>
    </div>
  );
};

export default Dashboard;
