import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { navbarLinks } from "../utils/routes";

const CustomNavbar = () => {
  return (
    <div>
      <Navbar expand="lg" style={{ backgroundColor: "#FFD700" }}>
        <Container>
          <Navbar.Brand></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              //className="me-auto"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Nav.Link key={"0000"} style={{ fontWeight: "700" }}>
                <Link
                  style={{
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                  }}
                  to={"/dashboard"}
                >
                  Dashboard
                </Link>
              </Nav.Link>

              {navbarLinks.map((item, index) => (
                <NavDropdown
                  key={item._id}
                  title={item.name}
                  id="basic-nav-dropdown"
                  renderMenuOnMount={true}
                  style={{ fontWeight: "700" }}
                >
                  {item.children
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      } else {
                        if (a.name > b.name) {
                          return 1;
                        } else return 0;
                      }
                    })
                    .map((innerItem) => (
                      <NavDropdown.Item key={innerItem._id}>
                        <Link
                          to={innerItem.link}
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {innerItem.name}
                        </Link>
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              ))}

              <Nav.Link key={"0001"} style={{ fontWeight: "700" }}>
                <Link
                  style={{
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                  }}
                  to={"/lecturePlanningMatrix"}
                >
                  Lecture Planning Matrix
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
