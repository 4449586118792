import { Formik } from "formik";
import _ from "lodash";
import { asyncMap } from "modern-async";
import moment from "moment";
import queryString from "query-string";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import { read, utils, writeFile } from "xlsx";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  GetAllDesignationAPIRoot,
  GetAllTestRoot,
  GetByIdStudentRoot,
  GetTestByIdRoot,
} from "../utils/types";

interface excelInputType {
  srNo: any | null;
  date: string | null;
  standard: string | null;
  subject: string | null;
  name: string | null;

  totalMarks: string | null;

  fromTime: string | null;
  toTime: string | null;
  teacher: string | null;
  branch: string | null;
  batch: string | null;
}

const SR_NO = 0;
const DATE = 1;
const STANDARD = 2;
const SUBJECT = 3;
const NAME_OF_TEST = 4;
const TOTALMARKS = 5;

const FROM_TIME = 6;
const TO_TIME = 7;
const TEACHER = 8;
const BRANCH = 9;
const BATCH = 10;

const Test = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [excelData, setExeclData] = useState<excelInputType[]>();

  const {
    get,
    post,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllTestRoot>("/test");
  const [key, setKey] = useState(Math.random());
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    (async () => await fetchData(selectedDate))();
  }, [get]);

  const fetchData = async (date: string) => {
    setRefreshing(true);

    try {
      await post("/getAllTodaysTest", { date: date });
      // await get();
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
      setKey(Math.random());
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setRefreshing(true);

      await deleteDesignation(`${_id}`).then((res) => {
        if (res.success) {
          Swal.fire({
            title: "Deleted!",
            text: "Your data has been deleted.",
            icon: "success",
          });
        }
      });
      await fetchData(selectedDate);
    } catch (err) {
      setRefreshing(false);
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const handleSampleExport = async () => {
    const arrData: string[][] = [];
    const headingArray: string[] = [
      "SR_NO",
      "DATE",
      "STANDARD",
      "SUBJECT",
      "NAME_OF_TEST",
      "TOTALMARKS",
      "FROM_TIME",
      "TO_TIME",
      "TEACHER",
      "BRANCH",
      "BATCH",
    ];

    arrData.push(headingArray);

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(workbook, "TestSampleExcel" + Date.now().toString() + ".xlsx");
  };

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const result = utils.sheet_to_json(firstSheet, { header: 1 }) as any[];

        const arr: excelInputType[] = [];
        result.map((x) => {
          Array.isArray(x) &&
            arr.push({
              srNo: x[SR_NO],
              date: x[DATE],
              standard: x[STANDARD],
              subject: x[SUBJECT],
              name: x[NAME_OF_TEST],
              totalMarks: x[TOTALMARKS],
              fromTime: x[FROM_TIME],
              toTime: x[TO_TIME],
              teacher: x[TEACHER],
              branch: x[BRANCH],
              batch: x[BATCH],
            });
        });

        const filteredData = arr.filter(
          (x) =>
            !(
              _.isEmpty(x.srNo) &&
              _.isEmpty(x.date) &&
              _.isEmpty(x.standard) &&
              _.isEmpty(x.subject) &&
              _.isEmpty(x.name) &&
              _.isEmpty(x.totalMarks) &&
              _.isEmpty(x.fromTime) &&
              _.isEmpty(x.toTime) &&
              _.isEmpty(x.teacher) &&
              _.isEmpty(x.branch) &&
              _.isEmpty(x.batch)
            )
        );

        setExeclData(filteredData.slice(1));
      };
      reader.readAsArrayBuffer(e.target.files![0]);

      toast.success("File read successfully");
    } catch (err) {
      toast.error("Something went wrong while uploading the file");
    }
  };
  const handleImport = async () => {
    try {
      handleClose();
      setRefreshing(true);

      if (excelData && excelData.length !== 0) {
        await asyncMap(excelData, async (x) => {
          await post("/excel", x)
            .then((res) => {})
            .catch((err) => console.log(err));
        });

        setRefreshing(false);

        toast.success("Test added");

        await fetchData(selectedDate);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const handleExport = async () => {
    const arrData: string[][] = [];
    const headingArray: string[] = [
      "Date",
      "Standard",
      "Subject",
      "Test name",
      "Total Marks",
      "From Time",
      "To Time",
      "Teacher",
      "Branch",
      "Batch",
    ];

    arrData.push(headingArray);

    response.data?.data.map((x) => {
      arrData.push([
        x.date,
        x.standard?.name,
        x.subject?.name,
        x.name,
        x.totalMarks,
        x.fromTime,
        x.toTime,
        x.teacher?.name,
        x.branch?.name,
        x.batch?.name,
      ]);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(workbook, "TestData" + Date.now().toString() + ".xlsx");
  };

  // if (loading || refreshing) {
  //   return (
  //     <div>
  //       <AppProvider>
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Spinner />
  //         </div>
  //       </AppProvider>
  //     </div>
  //   );
  // }

  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <AppProvider>
        <div key={key}>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Import Excel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Select excel file</Form.Label>
                    <Form.Control
                      type="file"
                      size="sm"
                      accept=".xlsx, .xls, .csv"
                      onChange={handleFileSelect}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleImport}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Test</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    {false && (
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleShow}
                      >
                        IMPORT
                      </Button>
                    )}

                    {false && (
                      <Button
                        variant="success"
                        size="sm"
                        onClick={handleExport}
                      >
                        EXPORT
                      </Button>
                    )}

                    {false && (
                      <Button
                        variant="info"
                        size="sm"
                        onClick={handleSampleExport}
                      >
                        SAMPLE
                      </Button>
                    )}

                    {true && (
                      <input
                        type="date"
                        style={{ borderRadius: "6px", padding: "6px" }}
                        value={selectedDate}
                        onChange={(e) => {
                          setSelectedDate(e.target.value);

                          fetchData(e.target.value);
                        }}
                      />
                    )}

                    {true && (
                      <Button
                        size="sm"
                        onClick={() => navigate("/test?action=add")}
                      >
                        ADD
                      </Button>
                    )}
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                {refreshing && <div>Loading</div>}

                <Table responsive bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name </th>
                      <th>Marks</th>
                      <th>Date</th>
                      <th>Subject</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Teacher</th>
                      <th>Branch</th>
                      <th>Batch</th>
                      <th>Standard</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((item: any, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>

                          <td>{item.name || ""}</td>
                          <td>{item.totalMarks || ""}</td>
                          <td>
                            {moment(item.date).format("DD-MM-YYYY") || ""}
                          </td>
                          <td>{item.subject.name || ""}</td>
                          <td>{item.fromTime || ""}</td>
                          <td>{item.toTime || ""}</td>
                          <td>{item.teacher.name || ""}</td>
                          <td>{item.branch.name || ""}</td>
                          <td>{item.batch.name || ""}</td>
                          <td>{item.standard.name || ""}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/test?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<GetAllTestRoot>("/test");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setRefreshing(true);

    try {
      await getBatch();

      await getBranch();

      await getStandard();

      await getSubject();

      await getTeacher();
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    description: string;
    totalMarks: string;
    standard: string;
    subject: string;
    teacher: string;
    batch: string;
    branch: string;
    date: string;
    fromTime: string;
    toTime: string;
    isActive: boolean;
  }) => {
    try {
      const res = await post(values);
      if (res.success) {
        navigate(-1);
      } else {
        alert(res.message || "Failed to add test. Please try again."); // Handle error cases
      }
    } catch (err) {
      alert("Error adding test"); // Handle network or other errors
      console.log(err);
    }
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Test</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required("subject is required"),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    teacher: Yup.string().required(),
                    standard: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    totalMarks: Yup.string().required(),

                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  initialValues={{
                    subject: "",
                    branch: "",
                    batch: "",
                    teacher: "",
                    standard: "",
                    name: "",
                    description: "",
                    totalMarks: "",
                    fromTime: "",
                    toTime: "",
                    date: "",
                    isActive: true,
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    handleSubmit(values)
                      .catch((error) => {
                        alert("Validation errors: " + error);
                        setErrors(error);
                      })
                      .finally(() => setSubmitting(false));
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Test name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Description <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Total Marks <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalMarks"
                            value={values.totalMarks}
                            onChange={handleChange}
                            isValid={touched.totalMarks && !errors.totalMarks}
                            isInvalid={!!errors.totalMarks}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.totalMarks}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik07"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();
  const { get, post } = useFetch<GetTestByIdRoot>("/test");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    subject: "",
    branch: "",
    batch: "",
    teacher: "",
    standard: "",
    name: "",
    description: "",
    totalMarks: "",
    fromTime: "",
    toTime: "",
    date: "",
    isActive: true,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");

  useEffect(() => {
    fetchData();
  }, [parsed.id, get]);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      await getStandard();
      await getBranch();
      await getBatch();
      await getSubject();
      await getTeacher();

      await get(`${parsed.id}`).then((res) => {
        if (res.success) {
          setInitValues({
            subject: res.data.subject._id,
            branch: res.data.branch._id,
            batch: res.data.batch._id,
            teacher: res.data.teacher._id,
            standard: res.data.standard._id,
            name: res.data.name,
            description: res.data.description,
            totalMarks: res.data.totalMarks,
            fromTime: res.data.fromTime,
            toTime: res.data.toTime,
            date: res.data.date,
            isActive: true,
          });

          setKey(Math.random());
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    description: string;
    totalMarks: string;
    standard: string;
    subject: string;
    teacher: string;
    batch: string;
    branch: string;
    date: string;
    fromTime: string;
    toTime: string;
    isActive: boolean;
  }) => {
    setRefreshing(true);

    try {
      await post(`${parsed.id}`, values)
        .then(async (res) => {
          if (res.success) {
            navigate(-1);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setRefreshing(false));
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Test</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required("subject is required"),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    teacher: Yup.string().required(),
                    standard: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    totalMarks: Yup.string().required(),

                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Test name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Description <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Total Marks <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalMarks"
                            value={values.totalMarks}
                            onChange={handleChange}
                            isValid={touched.totalMarks && !errors.totalMarks}
                            isInvalid={!!errors.totalMarks}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.totalMarks}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik07"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetByIdStudentRoot>("/lecture");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{response.data?.data.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Username</td>
                          <td>{response.data?.data.username || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Test;
