import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface LecturePlanningMatrixState {
  _id: string;
  teacher: string;
  subject: string;
  timeId: string;
  fromTime: string;
  toTime: string;
  branch: string;
  standard: string;
  isLecture: boolean;
  isSchool: boolean;
  date: string;
  batch: string;
}

export interface LecturePlanningMatrixDataState {
  data: LecturePlanningMatrixState[];
}

const initialState: LecturePlanningMatrixDataState = {
  data: [],
};

export const lecturePlanningMatrixSlice = createSlice({
  name: "LecturePlanningMatrix",
  initialState,
  reducers: {
    setLecturePlanningMatrixState: (
      state,
      action: PayloadAction<LecturePlanningMatrixState>
    ) => {
      state.data = [
        ...state.data.filter((x) => x._id !== action.payload._id),
        {
          _id: action.payload._id,
          subject: action.payload.subject,
          teacher: action.payload.teacher,
          fromTime: action.payload.fromTime,
          toTime: action.payload.toTime,
          timeId: action.payload.timeId,
          branch: action.payload.branch,
          standard: action.payload.standard,
          isLecture: action.payload.isLecture,
          isSchool: action.payload.isSchool,
          date: action.payload.date,
          batch: action.payload.batch,
        },
      ];
    },
    resetLecturePlanningMatrixState: (
      state,
      action: PayloadAction<{ _id: string }>
    ) => {
      state.data = [...state.data.filter((x) => x._id !== action.payload._id)];
    },
    clearLecturePlanningMatrixState: (state) => {
      state.data = [];
    },
  },
});

export const {
  setLecturePlanningMatrixState,
  resetLecturePlanningMatrixState,
  clearLecturePlanningMatrixState,
} = lecturePlanningMatrixSlice.actions;

export const selectLecture = (state: RootState) =>
  state.lecturePlanningMatrixReducer.data;

export default lecturePlanningMatrixSlice.reducer;
