import { Spinner } from "react-bootstrap";

const CustomLoader = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    </div>
  );
};

export default CustomLoader;
