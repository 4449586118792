import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import { API_URL, FILES_URL } from "../utils/constants";
import {
  AddDesignationRoot,
  FileUploadRoot,
  GetAllDesignationAPIRoot,
  GetAllParentDaum,
  GetAllParentRoot,
  GetAllStudentRoot,
  GetByIdStudentRoot,
  GetStudentByIdRoot,
  ParentChildByIdRoot,
  ParentChildRoot,
} from "../utils/types";

const Parent = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllParentRoot>("/parent");

  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [data, setData] = useState<GetAllParentDaum[]>([]);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res.success) {
            const currentData = res?.data.slice(
              currentPage * itemsPerPage,
              (currentPage + 1) * itemsPerPage
            );

            setData(currentData);
          }

          setKey(Math.random());

          setRefreshing(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setRefreshing(false));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            const currentData = res?.data.slice(
              currentPage * itemsPerPage,
              (currentPage + 1) * itemsPerPage
            );

            setData(currentData);
          }

          setKey(Math.random());

          setRefreshing(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);

    if (response.data?.data) {
      setData(
        response.data?.data.slice(
          selected * itemsPerPage,
          (selected + 1) * itemsPerPage
        )
      );
    }
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Parent</h5>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/parent?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body key={key}>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Email Address</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>{item?.name || ""}</td>
                          <td>{item?.username || ""}</td>
                          <td>{item?.email || ""}</td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                size={"sm"}
                                onClick={() =>
                                  navigate(
                                    `/parent?action=view&&id=${item._id}`
                                  )
                                }
                                variant="success"
                              >
                                View
                              </Button>
                              {/* <Button
                                onClick={() =>
                                  navigate(
                                    `/parent?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/parent");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get, response } = useFetch<GetAllStudentRoot>("/student");
  const { get: getRelation, response: relationResponse } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const [refreshing, setRefreshing] = useState(false);
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await get();

        await getRelation();
      } catch (err) {
        console.log(err);

        setRefreshing(false);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    name: string;
    username: string;
    password: string;
    isActive: boolean;
  }) => {
    await post(values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  if (parsed.type === "child") {
    return (
      <div>
        <AddChild />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Parent</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required(),
                    name: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    username: "",
                    password: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Password <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const AddChild = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/parentChild");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get, response } = useFetch<GetAllStudentRoot>("/student");
  const { get: getRelation, response: relationResponse } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const [refreshing, setRefreshing] = useState(false);
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const [initValues, setInitValues] = useState({
    relation: "",
    student: "",
    batch: "",
  });

  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await get();
        await getBatch();
        await getRelation();
      } catch (err) {
        console.log(err);
      } finally {
        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    batch: string;
    student: string;
    relation: string;
  }) => {
    setRefreshing(true);

    await post({ ...values, parent: parsed.id })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setRefreshing(false));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div>
                    <h6>Add Child</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    batch: Yup.string().required(),
                    student: Yup.string().required(),
                    relation: Yup.string().required(),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isValid={touched.batch && !errors.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Student <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="student"
                            onChange={handleChange}
                            value={values.student}
                            isValid={touched.student && !errors.student}
                            isInvalid={!!touched.student && !!errors.student}
                            key={values.batch}
                          >
                            <option value={""}>Select</option>

                            {response &&
                              response.data &&
                              response.data?.data &&
                              response.data?.data
                                .filter(
                                  (item) => item?.batch?._id === values?.batch
                                )
                                .map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.student}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Relation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="relation"
                            onChange={handleChange}
                            value={values.relation}
                            isValid={touched.relation && !errors.relation}
                            isInvalid={!!touched.relation && !!errors.relation}
                          >
                            <option value={""}>Select</option>

                            {relationResponse &&
                              relationResponse.data &&
                              relationResponse.data?.data &&
                              relationResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.relation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">ADD</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post, response } = useFetch<GetStudentByIdRoot>("/parent");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    name: "",
    username: "",
    relation: "",
    student: "",
    batch: "",
    photo: "",
    email: "",
    isActive: false,
  });
  const [childInitValues, setChildInitValues] = useState({
    relation: "",
    student: "",
    batch: "",
  });
  const [key, setKey] = useState(Math.random());

  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getStudent, response: studentResponse } =
    useFetch<GetAllStudentRoot>("/student");
  const { get: getRelation, response: relationResponse } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const [isUploading, setIsUploading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [childData, setChildData] = useState<
    { student: string; relation: string }[]
  >([]);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      // Verify if the selected file is an image
      const file = selectedFiles[0];
      if (!file.type.startsWith("image/")) {
        throw new Error("Please select an image file");
      }

      // Optional: You can further restrict based on file extensions if needed
      const acceptedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!fileExtension || !acceptedExtensions.includes(fileExtension)) {
        throw new Error(
          "Unsupported file extension. Please select an image file."
        );
      }

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then(async (res) => {
          return await res.json();
        })
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getStudent();

        await getRelation();

        if (parsed.id) {
          await get(`${parsed.id}`)
            .then((res) => {
              if (res.success) {
                if (res.data) {
                  setInitValues({
                    batch: res?.data?.student?.batch || "",
                    isActive: res?.data?.isActive,
                    name: res?.data?.name,
                    relation: res?.data?.relation?._id || "",
                    student: res?.data?.student?._id || "",
                    username: res?.data?.username || "",
                    email: res?.data?.email || "",
                    photo: "",
                  });

                  setKey(Math.random());

                  setRefreshing(true);
                }
              }
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    name: string;
    username: string;
    email: string;
    isActive: boolean;
    photo: string;
  }) => {
    setRefreshing(true);

    await post(`${parsed.id}`, {
      ...values,
      photo: filePath ? filePath : response.data?.data?.photo,
    })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChildSubmit = async (values: {
    batch: string;
    student: string;
    relation: string;
  }) => {
    setRefreshing(true);

    setChildData([
      ...childData,
      { relation: values.relation, student: values.student },
    ]);

    setRefreshing(false);
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  if (parsed.type === "child") {
    return (
      <div>
        <UpdateChild />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Parent</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    name: Yup.string().required(),
                    email: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Email Address
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Profile Photo
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="photo"
                            value={values.photo}
                            onChange={handleFileUpload}
                            isValid={touched.photo && !errors.photo}
                            isInvalid={!!errors.photo}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.photo}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const UpdateChild = () => {
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getStudent, response: studentResponse } =
    useFetch<GetAllStudentRoot>("/student");
  const { get: getRelation, response: relationResponse } =
    useFetch<GetAllDesignationAPIRoot>("/typeRelation");
  const { get, post } = useFetch<ParentChildByIdRoot>("/parentChild");

  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const [initValues, setInitValues] = useState({
    relation: "",
    parent: "",
    student: "",
    batch: "",
  });

  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getStudent();

        await getRelation();

        if (parsed.id) {
          await get(`${parsed.id}`)
            .then((res) => {
              if (res.success) {
                if (res.data) {
                  setInitValues({
                    batch: res?.data?.student?.batch || "",
                    parent: res?.data?.parent?._id || "",
                    relation: res?.data?.relation?._id || "",
                    student: res?.data?.student?._id || "",
                  });

                  setKey(Math.random());

                  setRefreshing(true);
                }
              }
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    parent: string;
    batch: string;
    student: string;
    relation: string;
  }) => {
    setRefreshing(true);

    await post(`${parsed.id}`, { ...values })
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setRefreshing(false));
  };

  if (refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Child</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    batch: Yup.string().required(),
                    student: Yup.string().required(),
                    relation: Yup.string().required(),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isValid={touched.batch && !errors.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Student <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="student"
                            onChange={handleChange}
                            value={values.student}
                            isValid={touched.student && !errors.student}
                            isInvalid={!!touched.student && !!errors.student}
                            key={values.batch}
                          >
                            <option value={""}>Select</option>

                            {studentResponse &&
                              studentResponse.data &&
                              studentResponse.data?.data &&
                              studentResponse.data?.data
                                .filter(
                                  (item) => item?.batch?._id === values?.batch
                                )
                                .map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.student}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Relation <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="relation"
                            onChange={handleChange}
                            value={values.relation}
                            isValid={touched.relation && !errors.relation}
                            isInvalid={!!touched.relation && !!errors.relation}
                          >
                            <option value={""}>Select</option>

                            {relationResponse &&
                              relationResponse.data &&
                              relationResponse.data?.data &&
                              relationResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.relation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetByIdStudentRoot>("/parent");
  const {
    post: getParentChild,
    response: parentChildResponse,
    delete: deleteParentChild,
  } = useFetch<ParentChildRoot>("/parentChild");

  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      if (parsed.id) {
        await get(`${parsed.id}`);

        await getParentChild(`/parent`, {
          parent: parsed.id,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteParentChild(`${_id}`)
      .then((res) => {
        if (res.success) {
          Swal.fire({
            title: "Deleted!",
            text: "Your data has been deleted.",
            icon: "success",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setRefreshing(false);

        fetchData();
      });
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return (
      <div>
        <AppProvider>
          <div>Something went wrong</div>
        </AppProvider>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <AppProvider>
          <div>{error.message}</div>
        </AppProvider>
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Parent Details</h6>
                  </div>
                </div>
              </Card.Header>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Personal</h6>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/parent?action=update&&type=personal&&id=${parsed.id}`
                        )
                      }
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response &&
                    response.ok &&
                    response.data &&
                    response.data?.data && (
                      <Table bordered hover>
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>{response.data?.data?.name || ""}</td>
                          </tr>

                          <tr>
                            <td>Username</td>
                            <td>{response.data?.data?.username || ""}</td>
                          </tr>

                          <tr>
                            <td>Email Address</td>
                            <td>{response.data?.data?.email || ""}</td>
                          </tr>

                          <tr>
                            <td>Is Active</td>
                            <td>
                              {response.data?.data?.isActive ? "Yes" : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Created At</td>
                            <td>
                              {moment(response.data?.data?.createdAt).format(
                                "DD-MM-YYYY H:mm A"
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Image</td>
                            <td>
                              <img
                                src={`${FILES_URL}/${response.data?.data?.photo}`}
                                width={200}
                                height={200}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Child</h6>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/parent?action=add&&type=child&&id=${parsed.id}`
                        )
                      }
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Student</th>
                        <th>Relation</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parentChildResponse &&
                        parentChildResponse.data &&
                        parentChildResponse?.data?.data &&
                        parentChildResponse.data?.data?.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.student?.name || ""}</td>
                              <td>{item?.relation?.name || ""}</td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      navigate(
                                        `/parent?action=update&&type=child&&id=${item._id}`
                                      )
                                    }
                                  >
                                    UPDATE
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          handleDelete(item._id);
                                        }
                                      });
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Parent;
