import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FetchData, useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  AddDesignationRoot,
  BackupSessionRoot,
  GetAllDesignationAPIRoot,
  GetAllLecturesRoot,
  GetAllStudentDaum,
  GetAllStudentRoot,
} from "../utils/types";

const BackupSession = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    post,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllLecturesRoot>("/backupSession");
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAction = async (values: { _id: string; isApproved: boolean }) => {
    setRefreshing(true);

    await post("/approve", {
      _id: values._id,
      isApproved: values.isApproved,
    })
      .then((res) => {
        if (res.success) {
          if (values.isApproved) {
            Swal.fire({
              title: "Approved!",
              text: "This Backup Session Has Been Approved.",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Rejected!",
              text: "This Backup Session Has Been Rejected.",
              icon: "success",
            });
          }
        } else {
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setRefreshing(false));

    await getData();

    setRefreshing(false);
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }
  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Backup Session</h5>

                  {false && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/backupSession?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Subject</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Teacher</th>
                      <th>Branch</th>
                      <th>Batch</th>
                      <th>Standard</th>
                      <th>Status</th>
                      <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {response &&
                      response.ok &&
                      response.data &&
                      response?.data?.data.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1 + currentPage * itemsPerPage}</td>
                            <td>
                              {moment(item.date).format("DD-MM-YYYY") || ""}
                            </td>
                            <td>{item.subject.name || ""}</td>
                            <td>{item.fromTime || ""}</td>
                            <td>{item.toTime || ""}</td>
                            <td>{item.teacher.name || ""}</td>
                            <td>{item.branch.name || ""}</td>
                            <td>{item.batch.name || ""}</td>
                            <td>{item.standard.name || ""}</td>
                            <td>{item.isActive ? "Yes" : "No"}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(
                                      `/lecture?action=update&&id=${item._id}`
                                    )
                                  }
                                >
                                  UPDATE
                                </Button>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(item._id);
                                      }
                                    });
                                  }}
                                >
                                  DELETE
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody> */}
                  <tbody>
                    {currentData &&
                      currentData.map((item: any, index: number) => (
                        <tr key={item._id}>
                          <td>{index + 1 + currentPage * itemsPerPage}</td>
                          <td>
                            {moment(item.date).format("DD-MM-YYYY") || ""}
                          </td>
                          <td>{item.subject.name || ""}</td>
                          <td>{item.fromTime || ""}</td>
                          <td>{item.toTime || ""}</td>
                          <td>{item.teacher.name || ""}</td>
                          <td>{item.branch.name || ""}</td>
                          <td>{item.batch.name || ""}</td>
                          <td>{item.standard.name || ""}</td>
                          <td>
                            {item.isActionPerformed
                              ? item.isApproved
                                ? "Approved"
                                : "Rejected"
                              : "Pending"}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex", gap: "1rem" }}>
                              {item.isActionPerformed ? (
                                <>
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() =>
                                      navigate(
                                        `/backupSession?action=view&&id=${item._id}`
                                      )
                                    }
                                  >
                                    VIEW
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() =>
                                      navigate(
                                        `/backupSession?action=view&&id=${item._id}`
                                      )
                                    }
                                  >
                                    VIEW
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, approve it!",
                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          await handleAction({
                                            _id: item._id,
                                            isApproved: true,
                                          });
                                        }
                                      });
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, reject it!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          handleAction({
                                            _id: item._id,
                                            isApproved: false,
                                          });
                                        }
                                      });
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </>
                              )}

                              {/* <Button
                                variant="success"
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/backupSession?action=view&&id=${item._id}`
                                  )
                                }
                              >
                                VIEW
                              </Button>
                              <Button
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/backupSession?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const { post: getStudents, response } =
    useFetch<GetAllStudentRoot>("/student");
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/backupSession");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const [attendanceList, setAttendanceList] = useState<
    { student: string; isPresent: boolean }[]
  >([]);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getBranch();

        await getStandard();

        await getSubject();

        await getTeacher();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    subject: string;
    branch: string;
    batch: string;
    teacher: string;
    standard: string;
    fromTime: string;
    toTime: string;
    date: string;
    isActive: boolean;
  }) => {
    if (attendanceList.length === 0) {
      alert("Please select students for backup session");

      return;
    }

    await post({
      ...values,
      attendanceList: attendanceList,
      isActionPerformed: true,
      isApproved: true,
    })
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Backup Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    teacher: Yup.string().required(),
                    standard: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  initialValues={{
                    subject: "",
                    branch: "",
                    batch: "",
                    teacher: "",
                    standard: "",
                    fromTime: "",
                    toTime: "",
                    date: "",
                    isActive: true,
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <GetStudentList
                        values={values}
                        getData={getStudents}
                        setAttendanceList={setAttendanceList}
                      />
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>Select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik07"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div>
          {response.data?.success && (
            <ManageStudent
              studentList={(response.data?.data as GetAllStudentDaum[]) || []}
              setAttendanceList={setAttendanceList}
              attendanceList={attendanceList}
            />
          )}
        </div>
      </AppProvider>
    </div>
  );
};

const GetStudentList: FC<{
  values: {
    subject: string;
    branch: string;
    batch: string;
    teacher: string;
    standard: string;
    fromTime: string;
    toTime: string;
    date: string;
    isActive: boolean;
  };
  getData: FetchData<GetAllStudentRoot>;
  setAttendanceList: (arg0: { student: string; isPresent: boolean }[]) => void;
}> = ({ getData, values, setAttendanceList }) => {
  useEffect(() => {
    (async () => {
      if (values.standard && values.batch && values.branch)
        await getData("/filter", {
          standard: values.standard,
          batch: values.batch,
          branch: values.branch,
        });

      setAttendanceList([]);
    })();
  }, [values]);

  return <></>;
};

const ManageStudent: React.FC<{
  studentList: GetAllStudentDaum[];
  setAttendanceList: (arg0: { student: string; isPresent: boolean }[]) => void;
  attendanceList: { student: string; isPresent: boolean }[];
}> = ({ studentList, attendanceList, setAttendanceList }) => {
  return (
    <Container
      style={{
        marginTop: "1rem",
      }}
    >
      <Card>
        <Card.Header>
          <div>
            <h6>Student for Backup Session</h6>
          </div>
        </Card.Header>

        <Card.Body>
          <Card.Body>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Add
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentList?.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            value={item._id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAttendanceList([
                                  ...attendanceList,
                                  { isPresent: false, student: item._id },
                                ]);
                              } else {
                                setAttendanceList(
                                  attendanceList.filter(
                                    (x) => x.student !== item._id
                                  )
                                );
                              }
                            }}
                          />
                        </Form.Group>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card.Body>
      </Card>
    </Container>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch("/backupSession");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    branch: "",
    batch: "",
    standard: "",
    subject: "",
    teacher: "",
    fromTime: "",
    toTime: "",
    date: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");

  useEffect(() => {
    (async () => {
      await getStandard();
      await getBranch();
      await getBatch();
      await getSubject();
      await getTeacher();

      await get(`${parsed.id}`)
        .then((res) => {
          if (res.success) {
            setInitValues({
              isActive: res.data?.isActive,
              branch: res.data?.branch?._id || " ",
              batch: res.data?.batch?._id || "",
              standard: res.data?.standard?._id || "",
              subject: res.data?.subject?._id || "",
              teacher: res.data?.teacher?._id || "",
              fromTime: res.data?.fromTime || "",
              toTime: res.data?.toTime || "",
              date: res.data?.date || "",
            });

            setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [parsed.id, get]);

  const handleSubmit = async (values: {
    branch: string;
    batch: string;
    standard: string;
    subject: string;
    teacher: string;
    fromTime: string;
    toTime: string;
    date: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Backup Session</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    subject: Yup.string().required(),
                    teacher: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>Select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>Select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<BackupSessionRoot>("/backupSession");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Backup Session</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Subject</td>
                          <td>{response.data?.data.subject?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Standard</td>
                          <td>{response.data?.data.standard?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Teacher</td>
                          <td>{response.data?.data.teacher?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Branch</td>
                          <td>{response.data?.data.branch?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Batch</td>
                          <td>{response.data?.data.batch?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Subject</td>
                          <td>{response.data?.data.subject?.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div>
                  <h6>Student List</h6>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td>Sr. No.</td>
                        <td>Name</td>
                      </tr>
                      {response.data?.data?.attendanceList?.map((x, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{x.student.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default BackupSession;
